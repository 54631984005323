import { StackView } from 'services/stack/StackService.types';
import { StructureView } from 'services/structure/StructureService.types';
import { ValueStreamView } from 'services/valueStream/ValueStreamService.types';
import { VerticalView } from 'services/vertical/VerticalService.types';

export enum TeamStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type TeamStatusString = keyof typeof TeamStatus;

export type ConfirmationStatusString = 'UPDATED' | 'WARNING' | 'CRITICAL' | 'INACTIVE';

export type TeamPaginationFilters = {
  names?: string[];
  verticals?: string[];
  structures?: string[];
  valueStreams?: string[];
  status?: TeamStatusString[];
  confirmationStatus?: ConfirmationStatusString[];
};

export type TeamPaginationView = {
  id: number;
  name: string;
  peopleAmount: number;
  situationStatus?: TeamStatusString;
  squad?: string;
  allocationAmount?: number;
};

export type ConfirmationStatusAmountProps = {
  update: number;
  warning: number;
  critical: number;
};

export type TeamPaginationResponse = {
  pagination: Pagination;
  filters?: TeamPaginationFilters;
  teams: TeamPaginationView[];
  peopleAmount: number;
  allocationAmount?: number;
  confirmationStatusAmount?: ConfirmationStatusAmountProps;
};

export type GetTeamsByValueStreamResponse = {
  pagination: Pagination;
  teams: TeamPaginationView[];
  totals?: {
    team: number;
    people: number;
    allocation: number;
  };
};

export type CreateTeamRequest = {
  name: string;
  verticalId: number;
  structureId: number;
  valueStreamId: number;
  squad: string;
  stackId: number;
  jiraKey: string;
  jiraTeamName: string;
};

export type HierarchyInfo = {
  vertical: string;
  structure: string;
  valueStream: string;
};

export type TeamView = {
  id: number;
  name: string;
  situationStatus?: TeamStatusString;
  director?: string;
  vertical?: VerticalView;
  structure?: StructureView;
  valueStream?: ValueStreamView;
  stack?: StackView;
  squad?: string;
  jiraKey?: string;
  jiraTeamName?: string;
  guardians?: Guardian[];
  hierarchyInfo?: HierarchyInfo;
};

export type GuardianTeam = {
  id: number;
  name: string;
  email: string;
  role: string;
};

export type updatedTeamGuardianRequest = {
  teamId: number;
  guardianIds: number[];
};

export type PatchGuardianTeamResponse = {
  guardians: GuardianTeam[];
};

export type UpdateTeamRequest = {
  id: number;
  name: string;
  verticalId: number;
  structureId: number;
  valueStreamId: number;
  situationStatus: string;
  squad: string;
  stackId: number;
  jiraKey: string;
  jiraTeamName: string;
};

export type GetTeamsRequest = {
  props?: string[];
};

export type GetTeamsResponse = {
  teams: TeamView[];
};

export type GetTeamsByValueStreamRequest = {
  valueStreamId: number;
  pagination: Pagination;
};

export type TeamsHasEmployeesResponse = {
  hasEmployees: boolean;
};

export type TeamStructureHierarchicalResponse = {
  vertical: string;
  structure: string;
  valueStream: string;
};

export type ConfirmationStatus = {
  label: string;
  status: 'UPDATED' | 'WARNING' | 'CRITICAL' | 'INACTIVE';
  color: string;
};

export type GetConfirmationStatusParameters = {
  confirmationStatus?: ConfirmationStatus[];
};

export type User = {
  name: string;
  email: string;
};

export type ConfirmationData = {
  status: 'CONFIRMED' | 'NEEDS_CONFIRMATION';
  lastConfirmationDate: string | null;
  user: User | null;
};

export type TeamConfirmationResponse = {
  teamId: number;
  confirmationData: ConfirmationData;
};

export type TeamLastAllocationUpdateResponse = {
  employee: string;
  date: string;
  user: {
    name: string;
    email: string;
  };
};

export type GetTeamsReviewResponse = {
  isPeriodAvailable: boolean;
};

export type GetTeamsReviewByIdResponse = {
  userCanConfirm: boolean;
  isReconfirm: boolean;
  hasEmployeeInTransition: boolean;
};
