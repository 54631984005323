import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const StyledParagraph = styled(Paragraph, {
  marginTop: '16px !important',
  marginBottom: '16px !important',
  maxWidth: '33rem',
});

const AttentionText = styled(StyledParagraph, {
  color: '#1414148F',
});

const StyledWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
});

export { StyledParagraph, AttentionText, StyledWrapper };
