import { generatePath } from 'react-router-dom';

export const VERTICALS_ROUTES = {
  verticals: '/verticals',
  verticalsPagination: '/verticals/filters',
  verticalsFiltersOptions: '/verticals/filters/options',
  verticalsSearch: '/verticals/filters/search',
  structuresByVertical: {
    route: '/verticals/:verticalId/structures',
    generate(verticalId) {
      return generatePath(this.route, { verticalId });
    },
  },
  verticalsById: {
    route: '/verticals/:verticalId',
    generate(verticalId) {
      return generatePath(this.route, { verticalId });
    },
  },
  defaultVerticals: '/verticals/withDefault',
  verticalExists: '/verticals/exists',
  verticalHasStructures: {
    route: '/verticals/:verticalId/hasStructures',
    generate(verticalId) {
      return generatePath(this.route, { verticalId });
    },
  },
};
