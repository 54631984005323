import { Paragraph } from '@hexa-ui/components-latest';
import AllocationsEmptyImg from 'assets/img/hand.svg';
import { Container } from './style';

const AllocationsEmpty = () => {
  return (
    <Container data-testid="empty-allocations">
      <img src={AllocationsEmptyImg} alt="empty allocations image" />
      <Paragraph size="basis">
        Hey! Don't forget to <br />
        allocate this person.
      </Paragraph>
    </Container>
  );
};

export default AllocationsEmpty;
