import { styled } from '@stitches/react';

const LoadingWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '80vh',
  width: '100%',
});

export { LoadingWrapper };
