import { createContextProvider } from '@hub/create-context';

export type Env = {
  env: {
    segmentKey: string;
    optimizelyKey: string;
  };
};

const initialValues = {
  env: {
    segmentKey: '',
    optimizelyKey: '',
  },
};

const [EnvProvider, useEnvProvider] = createContextProvider<Env>('EnvProvider', initialValues);

export { EnvProvider, useEnvProvider };
