import { ListItem, TextButton } from '@hexa-ui/components-latest';
import { MenuIcon } from 'assets/icons';
import { useState } from 'react';
import { useEmployeeFormStore } from 'stores';
import {
  BoxItems,
  Container,
  recalculateButtonStyles,
  RecalculateContainer,
  Total,
} from './styles';

const AllocationsFooter = () => {
  const {
    employeeAllocations,
    currentAllocationPercentageError,
    showLastMonthAllocation,
    getTotalAllocation,
    handleRecalculateEqually,
    handleRecalculateProportionally,
    hasDeletedPod,
    getTotalLastMonthAllocation,
  } = useEmployeeFormStore();

  const [isRecalculateMenuOpened, setIsRecalculateMenuOpened] = useState<boolean>(false);

  return (
    <Container data-testid="allocations-footer">
      <RecalculateContainer>
        <TextButton
          type="button"
          data-testid="recalculate-button"
          onClick={() => setIsRecalculateMenuOpened(!isRecalculateMenuOpened)}
          disabled={employeeAllocations.length === 0}
          css={{ ...recalculateButtonStyles }}
        >
          Recalculate <MenuIcon size="medium" />
        </TextButton>

        <BoxItems
          border="medium"
          elevated="small"
          id="box-items"
          isVisible={isRecalculateMenuOpened}
          onClick={() => setIsRecalculateMenuOpened(false)}
        >
          <ListItem
            data-testid="divide-allocation-equally-option"
            variant="selectable"
            onClick={() => handleRecalculateEqually()}
          >
            Divide allocation equally
          </ListItem>
          <ListItem
            data-testid="divide-allocation-proportionally-option"
            variant="selectable"
            onClick={() => handleRecalculateProportionally()}
            disabled={!hasDeletedPod}
          >
            Divide allocation proportionally
          </ListItem>
        </BoxItems>
      </RecalculateContainer>

      <Total data-testid="total-last-month-allocation" className="last-month-total">
        {showLastMonthAllocation && (
          <>
            Total last month allocation:
            <span> {getTotalLastMonthAllocation()?.toFixed(2)}</span>
          </>
        )}
      </Total>

      <Total
        data-testid="total-allocation"
        className={!!currentAllocationPercentageError && 'has-error'}
      >
        Total allocation: <span>{getTotalAllocation()?.toFixed(2)}</span>
      </Total>
    </Container>
  );
};

export default AllocationsFooter;
