import { Button } from '@hexa-ui/components-latest';
import { useState } from 'react';
import Allocations from '../EmployeeForm/Allocations';
import useCancelDialog from '../EmployeeForm/hooks/useCancelDialog';
import { BoxButtons, Container } from './styles';

const EditAllocation = () => {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const { showCancelDialog } = useCancelDialog();

  return (
    <Container elevated="medium" border="medium">
      <Allocations setDisableContinue={setDisableSubmit} />
      <BoxButtons>
        <Button
          type="button"
          data-testid="cancel-button"
          variant="secondary"
          onClick={() => showCancelDialog({ isAllocation: true })}
        >
          Cancel
        </Button>
        <Button
          form={`form-2`}
          type="submit"
          data-testid="save-button"
          variant="primary"
          disabled={disableSubmit}
        >
          Save
        </Button>
      </BoxButtons>
    </Container>
  );
};

export default EditAllocation;
