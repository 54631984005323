import { generatePath } from 'react-router-dom';

export const LINE_MANAGERS_ROUTES = {
  lineManagers: '/lineManagers',
  lineManagersHasEmployees: {
    route: '/lineManagers/:lineManagerId/hasEmployees',
    generate(lineManagerId) {
      return generatePath(this.route, { lineManagerId });
    },
  },
};
