import { Breadcrumb, Grid, Heading } from '@hexa-ui/components';
import { ChevronLeftIcon } from 'assets/icons';
import { BASE_NAME } from 'constants/appRoutes';
import { useAppHeader } from 'providers/AppHeaderProvider';
import { useRouterService } from 'providers/RouterServiceProvider';
import { Link, useLocation } from 'react-router-dom';
import {
  AlertsWrapper,
  AppHeaderWrapper,
  ButtonBackNavigation,
  ButtonsWrapper,
  StyledLink,
} from './styles';

export interface Crumb {
  name?: string;
  path?: string;
}

const AppHeader = () => {
  const { crumbs, pageTitle, optionalButtons, isVisible, alerts, backButtonFunction } =
    useAppHeader();
  const { navigate } = useRouterService();
  const location = useLocation();

  if (location.pathname === BASE_NAME) {
    return null;
  }

  const handleBackButtonOnClick = () => (backButtonFunction ? backButtonFunction() : navigate(-1));

  return crumbs?.length ? (
    <AppHeaderWrapper isVisible={isVisible}>
      <Grid.Item lg={12} md={12} sm={12} xl={12} xs={12}>
        {crumbs?.length > 0 && (
          <Breadcrumb.Root>
            <Breadcrumb.HomeItem asChild>
              <Link to={`${BASE_NAME}`} />
            </Breadcrumb.HomeItem>
            {crumbs.map(({ name }, key) => (
              <Breadcrumb.Item asChild key={key} isCurrentPage={key + 1 === crumbs.length}>
                <StyledLink
                  data-testid={`${name}-link`}
                  onClick={() => navigate((crumbs.length - key) * -1 + 1)}
                >
                  {name}
                </StyledLink>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb.Root>
        )}
      </Grid.Item>
      <Grid.Item lg={12} md={12} sm={12} xl={12} xs={12}>
        <ButtonBackNavigation
          data-testid="back-button"
          size="medium"
          icon={ChevronLeftIcon}
          variant="tertiary"
          onClick={handleBackButtonOnClick}
        />

        <Heading size="H2">{pageTitle}</Heading>
        <AlertsWrapper>{alerts}</AlertsWrapper>
        <ButtonsWrapper data-testid="optional-buttons-wrapper">{optionalButtons}</ButtonsWrapper>
      </Grid.Item>
    </AppHeaderWrapper>
  ) : (
    <></>
  );
};

export default AppHeader;
