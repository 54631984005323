import { DetailedListProps } from '../../types';
import {
  ListItemDescription,
  ListItemDetailsWrapper,
  ListItemTitle,
  ListItemWrapper,
} from './styles';

const DetailedListItem = ({ title, subtitle, description, avatar }: DetailedListProps) => {
  return (
    <ListItemWrapper>
      {/* <Avatar size="xlarge" image={avatar} variantType="initials">
        {title.substring(0, 2)}
      </Avatar> */}
      <ListItemDetailsWrapper>
        <ListItemTitle>
          {title} {subtitle ? `| ${subtitle}` : null}
        </ListItemTitle>
        <ListItemDescription>{description}</ListItemDescription>
      </ListItemDetailsWrapper>
    </ListItemWrapper>
  );
};

export default DetailedListItem;
