let structuresPerVerticalQuantity = 2;
let valueStreamsPerStructureQuantity = 2;
let teamsPerValueStreamQuantity = 2;
let employeesPerTeamQuantity = 2;
export const verticalsQuantity = 2;
export const companiesQuantity = 2;
export const directorsQuantity = 2;
export const stacksQuantity = 2;
export const rolesQuantity = 20;
export const roleGroupsQuantity = 4;
export const teamsQuantity =
  teamsPerValueStreamQuantity *
  valueStreamsPerStructureQuantity *
  structuresPerVerticalQuantity *
  verticalsQuantity;
export const allocationsQuantity = teamsQuantity * employeesPerTeamQuantity;
export const dateQuantity = 48;
export const allocationPlanningQuantity = 1;
export const logPerEmployeeQuantity = 1;
export const locationQuantity = 5;
export const meetingsQuantity = 30;

export const getStructuresPerVerticalQuantity = () => {
  return structuresPerVerticalQuantity;
};

export const setStructuresPerVerticalQuantity = (value: number) => {
  structuresPerVerticalQuantity = value;
};

export const getValueStreamsPerStructureQuantity = () => {
  return valueStreamsPerStructureQuantity;
};

export const setValueStreamsPerStructureQuantity = (value: number) => {
  valueStreamsPerStructureQuantity = value;
};

export const getTeamsPerValueStreamQuantity = () => {
  return teamsPerValueStreamQuantity;
};

export const setTeamsPerValueStreamQuantity = (value: number) => {
  teamsPerValueStreamQuantity = value;
};

export const getEmployeesPerTeamQuantity = () => {
  return employeesPerTeamQuantity;
};

export const setEmployeesPerTeamQuantity = (value: number) => {
  employeesPerTeamQuantity = value;
};
