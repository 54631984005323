export const COLUMNS_NAMES = ['POD', 'Last Allocation', 'Allocation'];

const POD_COLUMN_SIZE = 'auto';
const LAST_ALLOCATION_COLUMN_SIZE = '260px';
const ALLOCATION_COLUMN_SIZE = '180px';
const ACTION_COLUMN_SIZE = '50px';

// moved from settings OldEmployeeForm
export const TRANSITION_COLOR = '#FFAA33';

export const TEMPLATE_COLUMNS_SIZES = `${POD_COLUMN_SIZE} ${LAST_ALLOCATION_COLUMN_SIZE} ${ALLOCATION_COLUMN_SIZE} ${ACTION_COLUMN_SIZE}`;

export const TABLE_GRID_DEFAULT_STYLES = {
  display: 'grid',
  gridTemplateColumns: TEMPLATE_COLUMNS_SIZES,
  gap: '$8',
  height: 'fit-content',
};
