import { generatePath } from 'react-router-dom';

export const MEETING_ROUTES = {
  meeting: '/executiveMeetings',
  meetingDelete: {
    route: '/executiveMeetings/:date',
    generate(date) {
      return generatePath(this.route, { date });
    },
  },
};
