import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface Crumb {
  name?: string;
  path?: string;
}

interface AppHeaderProps {
  crumbs?: Crumb[];
  setCrumbs?: Dispatch<SetStateAction<Crumb[]>>;
  pageTitle?: string;
  setPageTitle?: Dispatch<SetStateAction<string>>;
  optionalButtons?: JSX.Element;
  setOptionalButtons?: Dispatch<SetStateAction<JSX.Element>>;
  isVisible?: boolean;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  alerts?: JSX.Element;
  setAlerts?: Dispatch<SetStateAction<JSX.Element>>;
  backButtonFunction?: () => void;
  setBackButtonFunction?: Dispatch<SetStateAction<() => void>>;
}

const AppHeaderContext = createContext<AppHeaderProps>({});

export const AppHeaderProvider = ({ children }) => {
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [pageTitle, setPageTitle] = useState('');
  const [optionalButtons, setOptionalButtons] = useState<JSX.Element>(null);
  const [alerts, setAlerts] = useState<JSX.Element>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [backButtonFunction, setBackButtonFunction] = useState<() => void>(null);

  return (
    <AppHeaderContext.Provider
      value={{
        backButtonFunction,
        setBackButtonFunction,
        crumbs,
        setCrumbs,
        pageTitle,
        setPageTitle,
        optionalButtons,
        setOptionalButtons,
        isVisible,
        setIsVisible,
        alerts,
        setAlerts,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

export const useAppHeader = () => useContext(AppHeaderContext);
