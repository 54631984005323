import { generatePath } from 'react-router-dom';

export const STRUCTURES_ROUTES = {
  structures: '/structures',
  structureById: {
    route: '/structures/:structureId',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  structuresPagination: '/structures/filters',
  structuresExists: '/structures/exists',
  structureHasValueStreams: {
    route: '/structures/:structureId/hasValueStreams',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  valueStreamsByStructure: {
    route: '/structures/:structureId/valueStreams',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  structuresByVerticals: '/structures/byVerticals'
};
