import { LoadingDots } from '@hexa-ui/components-latest';
import { LoadingWrapper } from './styles';

const Loading = () => {
  return (
    <LoadingWrapper>
      <LoadingDots dataTestId="loading-dots" />
    </LoadingWrapper>
  );
};

export default Loading;
