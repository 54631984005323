import immer from 'immer';
import { useReducer } from 'react';
import { REQUEST_STATUS } from '../../constants';

export type Action<T> = {
  type: string;
  response?: T;
  error?: any;
};

/* istanbul ignore next */
export const reducerActions = {
  error: <T,>(state: State<T>, action: Action<T>) => {
    state.status = REQUEST_STATUS.REJECTED;
    state.error = action.error;
  },
  success: <T,>(state: State<T>, action: Action<T>) => {
    state.status = REQUEST_STATUS.RESOLVED;
    state.response = { ...action.response };
  },
  started: <T,>(state: State<T>, _action: Action<T>) => {
    state.status = REQUEST_STATUS.PENDING;
  },
  reset: <T,>(state: State<T>, _action: Action<T>) => {
    state.status = REQUEST_STATUS.IDLE;
    state.error = null;
    state.response = null;
  },
};

/* istanbul ignore next */
export function reducer<T>(state: State<T>, action: Action<T>) {
  let fn = reducerActions[action.type];

  return immer(state, (draftState) => fn<T>(draftState, action));
}

export const useRequestReducer = <T,>() =>
  useReducer<(state: State<T>, action: Action<T>) => State<T>>(reducer, {
    status: REQUEST_STATUS.IDLE,
    error: null,
  });
