import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const StyledGrid = styled(Grid.Container, {
  rowGap: '1rem',
  paddingTop: '1rem',
  paddingBottom: '3rem',
});

export { StyledGrid };
