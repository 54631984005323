import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { APPLICATION_TITLE } from 'constants/optimizely';
import {
  AppHeaderProvider,
  AuthProvider,
  DialogProvider,
  EnvProvider,
  SidebarConfigProvider,
  ToastProvider,
} from 'providers';
import ServicesWrapper from 'utils/wrappers/ServicesWrapper';
import { IntlProvider } from './i18n';

interface Props {
  segmentKey: string;
  optimizelyKey: string;
}

export default function App(props: Props) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-capacity-management-mfe',
    seed: 'bees-hub-capacity-management-mfe',
  });

  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <ServicesWrapper optimizelyKey={props.optimizelyKey} applicationTitle={APPLICATION_TITLE}>
          <StylesProvider generateClassName={generateClassName}>
            <ToastProvider>
              <AuthProvider>
                <AppHeaderProvider>
                  <DialogProvider>
                    <SidebarConfigProvider />
                  </DialogProvider>
                </AppHeaderProvider>
              </AuthProvider>
            </ToastProvider>
          </StylesProvider>
        </ServicesWrapper>
      </IntlProvider>
    </EnvProvider>
  );
}
