// Status Codes
const CODE_100 = 100;
const CODE_101 = 101;
const CODE_102 = 102;
const CODE_103 = 103;
const CODE_200 = 200;
const CODE_201 = 201;
const CODE_202 = 202;
const CODE_203 = 203;
const CODE_204 = 204;
const CODE_205 = 205;
const CODE_206 = 206;
const CODE_207 = 207;
const CODE_208 = 208;
const CODE_226 = 226;
const CODE_300 = 300;
const CODE_301 = 301;
const CODE_302 = 302;
const CODE_303 = 303;
const CODE_304 = 304;
const CODE_305 = 305;
const CODE_306 = 306;
const CODE_307 = 307;
const CODE_308 = 308;
const CODE_400 = 400;
const CODE_401 = 401;
const CODE_402 = 402;
const CODE_403 = 403;
const CODE_404 = 404;
const CODE_405 = 405;
const CODE_406 = 406;
const CODE_407 = 407;
const CODE_408 = 408;
const CODE_409 = 409;
const CODE_410 = 410;
const CODE_411 = 411;
const CODE_412 = 412;
const CODE_413 = 413;
const CODE_414 = 414;
const CODE_415 = 415;
const CODE_416 = 416;
const CODE_417 = 417;
const CODE_418 = 418;
const CODE_421 = 421;
const CODE_422 = 422;
const CODE_423 = 423;
const CODE_424 = 424;
const CODE_425 = 425;
const CODE_426 = 426;
const CODE_429 = 429;
const CODE_431 = 431;
const CODE_451 = 451;
const CODE_500 = 500;
const CODE_501 = 501;
const CODE_502 = 502;
const CODE_503 = 503;
const CODE_504 = 504;
const CODE_505 = 505;
const CODE_506 = 506;
const CODE_507 = 507;
const CODE_508 = 508;
const CODE_510 = 510;
const CODE_511 = 511;

export const HTTP_STATUS_CODE = {
  CODE_100,
  CODE_101,
  CODE_102,
  CODE_103,
  CODE_200,
  CODE_201,
  CODE_202,
  CODE_203,
  CODE_204,
  CODE_205,
  CODE_206,
  CODE_207,
  CODE_208,
  CODE_226,
  CODE_300,
  CODE_301,
  CODE_302,
  CODE_303,
  CODE_304,
  CODE_305,
  CODE_306,
  CODE_307,
  CODE_308,
  CODE_400,
  CODE_401,
  CODE_402,
  CODE_403,
  CODE_404,
  CODE_405,
  CODE_406,
  CODE_407,
  CODE_408,
  CODE_409,
  CODE_410,
  CODE_411,
  CODE_412,
  CODE_413,
  CODE_414,
  CODE_415,
  CODE_416,
  CODE_417,
  CODE_418,
  CODE_421,
  CODE_422,
  CODE_423,
  CODE_424,
  CODE_425,
  CODE_426,
  CODE_429,
  CODE_431,
  CODE_451,
  CODE_500,
  CODE_501,
  CODE_502,
  CODE_503,
  CODE_504,
  CODE_505,
  CODE_506,
  CODE_507,
  CODE_508,
  CODE_510,
  CODE_511,
};

export const INFORMATION_CODE = [CODE_100, CODE_101, CODE_102, CODE_103];

export const SUCCESS_CODE = [
  CODE_200,
  CODE_201,
  CODE_202,
  CODE_203,
  CODE_204,
  CODE_205,
  CODE_206,
  CODE_207,
  CODE_208,
  CODE_226,
];

export const CLIENT_ERROR_CODE = [
  CODE_400,
  CODE_401,
  CODE_402,
  CODE_403,
  CODE_404,
  CODE_405,
  CODE_406,
  CODE_407,
  CODE_408,
  CODE_409,
  CODE_410,
  CODE_411,
  CODE_412,
  CODE_413,
  CODE_414,
  CODE_415,
  CODE_416,
  CODE_417,
  CODE_418,
  CODE_421,
  CODE_422,
  CODE_423,
  CODE_424,
  CODE_425,
  CODE_426,
  CODE_429,
  CODE_431,
  CODE_451,
];

export const SERVER_ERROR_CODE = [
  CODE_500,
  CODE_501,
  CODE_502,
  CODE_503,
  CODE_504,
  CODE_505,
  CODE_506,
  CODE_507,
  CODE_508,
  CODE_510,
  CODE_511,
];

export const ERROR_CODE = [...CLIENT_ERROR_CODE, ...SERVER_ERROR_CODE];
