import { generatePath } from 'react-router-dom';

export const VALUE_STREAMS_ROUTES = {
  valueStreams: '/valueStreams',
  valueStreamById: {
    route: '/valueStreams/:valueStreamId',
    generate(valueStreamId) {
      return generatePath(this.route, { valueStreamId });
    },
  },
  valueStreamsExists: '/valueStreams/exists',
  valueStreamsPagination: '/valueStreams/filters',
  teamsByValueStream: {
    route: '/valueStreams/:valueStreamId/teams',
    generate(valueStreamId) {
      return generatePath(this.route, { valueStreamId });
    },
  },
  valueStreamHasTeams: {
    route: '/valueStreams/:valueStreamId/hasTeams',
    generate(valueStreamId) {
      return generatePath(this.route, { valueStreamId });
    },
  },
  valueStreamByIds: '/valueStreams/byIds',
};
