import { faker } from '@faker-js/faker';
import { Factory, trait } from 'miragejs';
import { LOG_TYPES } from '../constants/log';
import {
  allocationPlanningQuantity,
  companiesQuantity,
  directorsQuantity,
  getEmployeesPerTeamQuantity,
  getStructuresPerVerticalQuantity,
  getTeamsPerValueStreamQuantity,
  getValueStreamsPerStructureQuantity,
  logPerEmployeeQuantity,
  roleGroupsQuantity,
  rolesQuantity,
  stacksQuantity,
} from '../constants/mockedServer';
import { EmployeeStatus } from '../services/employee/EmployeeService.types';
import { StructureStatus } from '../services/structure/StructureService.types';
import { TeamStatus } from '../services/team/TeamService.types';
import { ValueStreamStatus } from '../services/valueStream/ValueStreamService.types';
import { randomIntFromInterval, randomIntOrZeroOrNull } from '../utils/generic';
import { roleGroupsNames, rolesNames } from './mocks';
import { isOdd } from './utils/number';
import { getStatusByNumber } from './utils/status';

const factories = {
  vertical: Factory.extend({
    name(i) {
      return `Vertical ${i + 1}`;
    },
    withStructures: trait({
      afterCreate(vertical, server) {
        server.createList(
          'structure',
          getStructuresPerVerticalQuantity(),
          'withValueStreams',
          'withDirector',
          {
            vertical,
          }
        );
      },
    }),
  }),

  structure: Factory.extend({
    name(i) {
      return `Structure ${i + 1}`;
    },
    situationStatus(i) {
      return getStatusByNumber(i, StructureStatus.Active, StructureStatus.Inactive);
    },
    planModified() {
      return new Date().toISOString();
    },
    withValueStreams: trait({
      afterCreate(structure, server) {
        server.createList('valueStream', getValueStreamsPerStructureQuantity(), 'withTeams', {
          structure,
        });
      },
    }),
    withDirector: trait({
      afterCreate(structure, server) {
        const director = server.schema.directors.find(randomIntFromInterval(1, directorsQuantity));
        structure.update({ director });
        structure.save();
      },
    }),
    guardians() {
      return [
        {
          id: 1,
          name: 'Guardian 1',
          email: 'email@example.com',
          role: 'CEO',
          isLineManager: true,
        },
      ];
    },
  }),

  valueStream: Factory.extend({
    name(i) {
      return `Value Stream ${i + 1}`;
    },
    situationStatus(i) {
      return getStatusByNumber(i, ValueStreamStatus.Active, ValueStreamStatus.Inactive);
    },
    type(i) {
      return {
        id: i + 1,
        name: `Type ${i + 1}`,
      };
    },
    guardians() {
      return [
        {
          id: 1,
          name: 'Value Stream Guardian 1',
          email: 'email@example.com',
          role: 'CEO',
        },
      ];
    },
    withTeams: trait({
      afterCreate(valueStream, server) {
        server.createList(
          'team',
          getTeamsPerValueStreamQuantity(),
          'withDirector',
          'withStack',
          'withEmployees',
          {
            valueStream,
          }
        );
      },
    }),
  }),

  team: Factory.extend({
    name(i) {
      return `Pod ${i + 1}`;
    },
    situationStatus(i) {
      return getStatusByNumber(i, TeamStatus.Active, TeamStatus.Inactive);
    },
    peopleAmount(i) {
      return isOdd(i) ? 3 : 0;
    },
    hasPositionRequested() {
      return Math.random() < 0.5;
    },
    guardians() {
      return [
        {
          id: 1,
          name: 'Pod 1',
          email: 'email@example.com',
          role: 'CEO',
          isLineManager: true,
        },
      ];
    },
    withDirector: trait({
      afterCreate(team, server) {
        const director = server.schema.directors.find(randomIntFromInterval(1, directorsQuantity));
        team.update({ director });
        team.save();
      },
    }),
    withStack: trait({
      afterCreate(team, server) {
        const stack = server.schema.stacks.find(randomIntFromInterval(1, stacksQuantity));
        team.update({ stack });
        team.save();
      },
    }),
    withEmployees: trait({
      afterCreate(team, server) {
        const teams = [team];
        server.createList(
          'employee',
          getEmployeesPerTeamQuantity(),
          'withCompany',
          'withRole',
          'withLineManager',
          'insertOneMoreTeam',
          'withLogs',
          {
            teams,
          }
        );
      },
    }),
  }),

  employee: Factory.extend({
    name() {
      return faker.name.fullName();
    },
    email() {
      return faker.internet.email();
    },
    employeeNumber() {
      return faker.random.numeric();
    },
    allocation() {
      return 1;
    },
    isLineManager() {
      const lineManagerStatus = [true, false];
      return lineManagerStatus[Math.floor(Math.random() * lineManagerStatus.length)];
    },
    situationStatus(i) {
      return getStatusByNumber(i, EmployeeStatus.Active, EmployeeStatus.Inactive);
    },
    inactivatedOn(i) {
      return isOdd(i) ? new Date().toLocaleDateString('fr-CA') : null;
    },
    inactivatedBy(i) {
      return isOdd(i) ? faker.name.fullName() : null;
    },
    withCompany: trait({
      afterCreate(employee, server) {
        const company = server.schema.companies.find(randomIntFromInterval(1, companiesQuantity));
        employee.update({ company });
        employee.save();
      },
    }),
    withRole: trait({
      afterCreate(employee, server) {
        const role =
          Number(employee.id) == 1
            ? server.schema.roles.find(1)
            : server.schema.roles.find(randomIntFromInterval(1, rolesQuantity));
        employee.update({ role });
        employee.save();
      },
    }),
    withLineManager: trait({
      afterCreate(employee, server) {
        const lineManagers = server.schema.employees.where({ isLineManager: true });
        const lineManager = lineManagers.models[Math.floor(Math.random() * lineManagers.length)];

        if (lineManager && lineManager?.id !== employee.id) {
          employee.lineManagerId = lineManager.id;
          employee.save();
        }
      },
    }),
    insertOneMoreTeam: trait({
      afterCreate(employee, server) {
        const allTeams = server.schema.teams.all().models;
        const team = server.schema.teams.find(randomIntFromInterval(1, allTeams.length));
        const employeeAlreadyInThisTeam = employee.teamIds.includes(team.id);

        if (!employeeAlreadyInThisTeam && team.situationStatus === EmployeeStatus.Active) {
          employee.teamIds.push(team.id);
          employee.save();
        }
      },
    }),
    withLogs: trait({
      afterCreate(employee, server) {
        server.createList('log', logPerEmployeeQuantity, 'withEmployeeLogs', {
          employee,
        });
      },
    }),
  }),

  allocation: Factory.extend({
    percentage() {
      return Number(Math.random().toFixed(2));
    },
    userWhoSavedTheRecord() {
      return faker.internet.email();
    },
    startDate() {
      const date = new Date();
      const day = date.getDate() - 1 === 0 ? 1 : date.getDate() - 1;
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    endDate() {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    withTeamsAndEmployees: trait({
      afterCreate(allocation, server) {
        const allTeams = server.schema.teams.all().models;
        const allEmployees = server.schema.employees.all().models;
        const employee = server.schema.employees.find(
          randomIntFromInterval(1, allEmployees.length)
        );
        const log = server.schema.logs.find(employee.logIds[0]);
        const team = server.schema.teams.find(randomIntFromInterval(1, allTeams.length));
        const logTypeTitleDescObj = {
          type: LOG_TYPES.TITLE_DESC,
          title: `Allocation at ${team.name}`,
          description: `Allocation amount: ${allocation.percentage}`,
        };
        allocation.teamId = team.id;
        allocation.employeeId = employee.id;
        log.events.push(logTypeTitleDescObj);
        log.save();
        allocation.save();
      },
    }),
  }),

  meeting: Factory.extend({
    date(i) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      return date.toISOString().slice(0, 10);
    },
  }),

  company: Factory.extend({
    name(i) {
      return `Company ${i + 1}`;
    },
    location(i) {
      return faker.address.country(i);
    },
    external() {
      const externalStatus = [true, false];
      return externalStatus[Math.floor(Math.random() * externalStatus.length)];
    },
    situationStatus: 'Active',
  }),

  location: Factory.extend({
    name(i) {
      return faker.address.country(i);
    },
  }),

  role: Factory.extend({
    name(i) {
      return rolesNames[i];
    },
    situationStatus: 'Active',
    withGroups: trait({
      afterCreate(role, server) {
        const group = server.schema.roleGroups.find(randomIntFromInterval(1, roleGroupsQuantity));
        role.update({ group });
        role.save();
      },
    }),
  }),

  roleGroup: Factory.extend({
    name(i) {
      return roleGroupsNames[i];
    },
    situationStatus: 'Active',
  }),

  director: Factory.extend({
    name(i) {
      return `Director ${i + 1}`;
    },
  }),

  stack: Factory.extend({
    name(i) {
      return `Stack ${i + 1}`;
    },
  }),

  allocationPlanning: Factory.extend({
    plan() {
      return randomIntOrZeroOrNull(randomIntFromInterval(1, 3));
    },
    validated() {
      return randomIntOrZeroOrNull(randomIntFromInterval(1, 3));
    },
    forecast() {
      return randomIntOrZeroOrNull(randomIntFromInterval(1, 3));
    },
    withTeams: trait({
      afterCreate(allocationPlanning, server) {
        const allTeams = server.schema.teams.all().models;
        const randomTeam = server.schema.teams.find(randomIntFromInterval(1, allTeams.length));
        allocationPlanning.teamId = randomTeam.id;
        allocationPlanning.save();
      },
    }),
  }),

  date: Factory.extend({
    value(i) {
      const month = i + 1;
      const year = new Date().getFullYear();

      if (month <= 12) return month < 10 ? `0${month}/${year}` : `${month}/${year}`;
      else if (month <= 24)
        return month - 12 < 10 ? `0${month - 12}/${year + 1}` : `${month - 12}/${year + 1}`;
      else if (month <= 36)
        return month - 24 < 10 ? `0${month - 24}/${year + 2}` : `${month - 24}/${year + 2}`;
      else if (month <= 48)
        return month - 36 < 10 ? `0${month - 36}/${year + 3}` : `${month - 36}/${year + 3}`;
    },
    editable(i) {
      const currentMonth = new Date().getMonth();
      return i >= currentMonth ? true : false;
    },
    start(i) {
      const currentMonth = new Date().getMonth();
      return i === currentMonth;
    },
    withAllocationPlanning: trait({
      afterCreate(planningDate, server) {
        server.createList('allocationPlanning', allocationPlanningQuantity, 'withTeams', {
          planningDate,
        });
      },
    }),
  }),

  log: Factory.extend({
    dateTime() {
      return new Date().toISOString().slice(0, 19).replace('T', ' ');
    },
    authorId() {
      return faker.internet.email();
    },
    authorName() {
      return faker.name.fullName();
    },
    events() {
      return [];
    },
    withEmployeeLogs: trait({
      afterCreate(log) {
        const logTypeTitleDetailsObj = {
          type: LOG_TYPES.TITLE_DETAILS,
          title: 'Registration creation',
          details: {
            email: log.employee.email,
            name: log.employee.name,
            lineManager: log.employee?.lineManager?.name ? log.employee.lineManager.name : null,
            isLineManager: log.employee.isLineManager ? 'Yes' : 'No',
            employeeNumber: log.employee.employeeNumber,
            role: log.employee?.role?.name,
            company: log.employee?.company?.name,
          },
        };
        log.events.push(logTypeTitleDetailsObj);
        log.save();
      },
    }),
  }),
};

export default factories;
