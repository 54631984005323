export const podsNames = [
  '3S_Bees Apiary',
  '3S_Bees Capacity',
  '3S_Bees Move',
  'Acquisition',
  'Admin',
  'Admin Portal Framework',
  'Algo Missions',
  'Android',
  'Antarctica',
  'Appia (Force)',
  'AppSec',
  'Backend',
  'Beats',
  'Becks',
  'BEES Academy',
  'BEES Sync 1 Backend',
  'BEES Sync 2 Backend',
  'BEESIFY Core',
  'Blueteam - Security',
  'Bohemia (Force)',
  'BP Catalog Tools BE 1',
  'BP Catalog Tools BE 2',
  'BP Catalog Tools FE 1',
  'BP Catalog Tools FE 2',
  'Business Agility',
  'Business Partners',
  'CAD - Category Admin',
  'CAD_CatAdmin BE 1',
  'CAD_CatAdmin BE 2',
  'CAD_CatAdmin FE 1',
  'CAD_CatAdmin FE 2',
  'Capacity Management',
  'CAREAG_Beats',
  'CAREOP_H2O',
  'CAREPS_Skol',
  'CAREPS_Wals',
  'Cart Web',
  'Catalog 1',
  'Catalog 2',
  'Catalog 3',
  'Catalog 5',
  'Catalog 6',
  'Catalog 7',
  'Checkout Web',
  'Cloud Ops - Integration',
  'Cloud Ops 1',
  'Cloud Ops 2',
  'Cloud Ops 3',
  'CMS',
  'Comunicação',
  'Corona (Force)',
  'Cyber Security',
  'Data Mesh - POD Infra 1',
  'Data Mesh - POD Infra 2',
  'Data Mesh - POD Infra 3',
  'Data Team - Aud Segmentation',
  'Data Team - Campaign Measurement',
  'DC - Digital Coupon',
  'Deals Backend',
  'Deals Frontend',
  'Deals Fullstack',
  'Deals Web',
  'Deliver - APP1',
  'Deliver - APP2',
  'Deliver - APP3',
  'Deliver - APP4',
  'Deliver - APP5',
  'Deliver - Portal1',
  'Deliver - Portal2',
  'Deliver - Portal3',
  'Deliver - Value Stream 1',
  'Delivery Excellence',
  'DX_DoBem (Automate)',
  'DX_Karmeliet (Automate)',
  'DX_Michelob (Automate)',
  'Engage POD1',
  'Engage POD2',
  'Engage POD3',
  'Evolution',
  'Expansion',
  'Facilities',
  'Finance',
  'Force Architecture',
  'Force Migration',
  'Foundation Android',
  'Foundation iOS',
  'Franziskaner (Force)',
  'Franziskaner Dunkel',
  'Franziskaner Hell',
  'Franziskaner Kristall-Klar',
  'Frontline Transformation',
  'FSM_Hoegaarden',
  'GD_Wäls Brut',
  'Goose (Force)',
  'Hertog Jan BockBier (Migration)',
  'Hertog Jan Dubbel (Migration)',
  'Hertog Jan Enkel (Migration)',
  'Hertog Jan Lentebock (Migration)',
  'Hertog Jan Pilsener (Migration)',
  'Hertog Jan Tripel (Migration)',
  'Hiring',
  'Home & Search Web',
  'Home Page & Search Backend',
  'Home Page & Search Frontend',
  'ID_Digital Identity 1',
  'ID_Digital Identity 2',
  'ID_Digital Identity 3',
  'ID_Digital Identity 4',
  'ID_Digital Identity 5',
  'ID_Identity FrontEnd',
  'ID_Identity FrontEnd 2',
  'IE-Colombia',
  'IE-Mexico',
  'Information Security',
  'iOS',
  'ISec Governance',
  'IV_Honeycomb (Inventory)',
  'Learning',
  'Leffe Ruby (Force)',
  'Link - Bumblebee',
  'Link - Daruk',
  'Link - Ezlo',
  'Link - Mipha',
  'Link - Navi',
  'Link - Nazgul',
  'Link - Zelda',
  'Michelob (Force)',
  'Migration',
  'Missions Experience',
  'Missions Experience 2',
  'Mobile',
  'Mobile-1',
  'Mobile-2',
  'NAZ Integration 1',
  'NAZ Integration 2',
  'NFA Core BFF',
  'NFA Core QA',
  'NFA Core Render',
  'NFA Support',
  'Norteña (Cart)',
  'Order Management',
  'Original (Force)',
  'OS_Order Management 1 (Order Services)',
  'OS_Order Management 2 (Order Services)',
  'OS_Order Management 3 (Order Services)',
  'OS_Order Management FE (Order Services)',
  'OS_Order Shop 1 (Order Services)',
  'OS_Order Shop 2 (Order Services)',
  'Partner - Becks',
  'Partner - Colorado',
  'Partner - Frans',
  'Partner - Lion',
  'Partner - Patagonia',
  'Partner - Patricia',
  'Partner - Spaten',
  'Patagonia (Force)',
  'Platform',
  'Platform Core 1',
  'Platform Core 2',
  'PMO',
  'POD 1 (Data Platform)',
  'POD 10 (Data Platform)',
  'POD 2 (Data Platform)',
  'POD 3 (Data Platform)',
  'POD US (Data Platform)',
  'Pricing 1 (Wäls Petroleum)',
  'Pricing 2 (Wäls Impetus)',
  'Pricing 3 (Wäls Dubbel)',
  'Pricing 4 (Wäls Trippel)',
  'Procurement',
  'Product Page Backend',
  'Product Pages Frontend',
  'Product Pages Web',
  'Profile Management',
  'Promotions (Catalog 4)',
  'Revenue Management',
  'RPG_POD 1',
  'RPG_POD 2',
  'RPG_POD 3',
  'RPG_POD 4',
  'RPMO',
  'SaaS Feature Activation POD1',
  'SaaS Feature Activation POD2',
  'SaaS Implementation POD1',
  'SaaS Implementation POD2',
  'SaaS Tools POD1',
  'SaaS Tools POD2',
  'Secure Development',
  'Serramalte (Cart)',
  'Serramalte (Force)',
  'Serrana (BFF)',
  'Services',
  'Social Media POD 1',
  'Social Media POD 2',
  'Spaten (Force)',
  'ST_Escutelata',
  'ST_Honeycomb',
  'ST_Hornet',
  'ST_Keeper',
  'ST_Mellifera',
  'Stella (Force)',
  'Sting',
  'Sukita (Checkout)',
  'Supervisor Experience',
  'US Operations',
  'Vendo_Backend',
  'Vendo_Frontend',
  'Web',
  'X-Wals (Force)',
];

export const verticalsNames = ['Growth Technology', 'BEES', 'TECHNOLOGY'];

export const structuresNames = [
  'BEES Partner',
  'BEES Grow',
  'Migration',
  'BEES Force',
  'BEES Link',
  'BEES Care',
  'SaaS',
  'Seed Software Services',
  'Shared Services',
  'BEES Data',
  'Martech',
  'BEES Deliver',
  'Cloud SecOps',
  'BEES Customer',
  'Platform Services',
  'BEES Sting',
];

export const valueStreamsNames = [
  'Missions',
  'BEES Vision',
  'Partner Success',
  'Close the Fork',
  'Admin Portal',
  'Vendo',
  'My Performance',
  'Digital Identity',
  'Route Planning',
  'Membership',
  'Digital Coupons',
  'People',
  'Catalog',
  'Analytics',
  'Core',
  'Data Mass',
  'Capacity Management',
  'Operations',
  'Shopping Experience',
  'Audience Management',
  'SaaS',
  'Revenue Management',
  'International Expansion',
  'Customer Support',
  'Rewards',
  'New Profiles & Tools',
  'Security',
  'Operational Tools',
  'Expansion',
  'BEES Lite',
  'Consumer Insights',
  'Mobile Architecture',
  'Portal',
  'BEES Academy',
  'NAZ',
  'Agent',
  'Data Engineering',
  'Architecture',
  'NFA',
  'My Key Account',
  'Fintech',
  'Order Taking',
  'Algo Tasking',
  'App',
  'Finance & Methodology',
  'Inventory Visibility',
  'SAAS',
  'Platform Operation',
  'Cart & Checkout',
  'Product Services',
  'Orders',
  'Pricing',
  'CMS',
  'Integration',
  'Mission Oriented',
  'Algo Selling',
  'BEESify',
  'BEES Social Media',
  'Martech Rewards',
  'Insights & Grow',
  'Automate',
  'Partner Services',
  'Frontline Transformation',
  'User Performance',
  'Migration & Expansion',
  'Migration',
  'EDI',
];

export const valueStreamOrder = {
  Active: 1,
  Inactive: 2,
};

export const rolesNames = [
  'DIRECTOR',
  'ARCHITECT',
  'ASSISTANT',
  'DEVELOPER',
  'ENABLERS',
  'FINANCE',
  'INTERN',
  'OTHERS',
  'PEOPLE',
  'PROCUREMENT',
  'TRAINEE',
  'SALES',
  'CEO',
  'PRODUCT MANAGER',
  'PRODUCT OWNER',
  'SCRUM MASTER',
  'UI/UX',
  'AGILE COACH',
  'DATA ENGINEER',
  'DATA MANAGER',
];

export const company = {
  id: 1,
  name: 'Company test',
  external: true,
  location: { id: 1, name: 'Brazil' },
  situationStatus: 'Active',
};

export const locations = ['Brazil', 'Japan', 'London', 'Mexico', 'Uruguay'];

export const roleGroupsNames = ['Developer', 'TPM', 'Sales', 'General'];

export const mockFeatureToggleResponse = {
  enabled: [true, false],
  disabled: [false, false],
  loading: [undefined, true],
};
