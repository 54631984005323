import { Button } from '@hexa-ui/components';
import { useDialogContext } from 'providers';
import { useEmployeeFormStore } from 'stores';

const useSuccessInTransitionDialog = () => {
  const { showDialog, hideDialog } = useDialogContext();
  const { setSuccessDialogHasBeenClosed } = useEmployeeFormStore();

  const showSuccessInTransitionDialog = () => {
    return showDialog({
      title: 'Success!',
      description:
        'As soon as this person reallocated to a new POD, the transition process will be completed!',
      actions: (
        <Button
          data-testid="success-dialog-ok-in-transition-button"
          size="large"
          variant="primary"
          onClick={() => {
            hideDialog();
            setSuccessDialogHasBeenClosed(true);
          }}
        >
          Ok, understood
        </Button>
      ),
    });
  };

  return { showSuccessInTransitionDialog };
};

export default useSuccessInTransitionDialog;
