import { AbbreviatedMonths } from 'constants/index';
import { format } from 'date-fns';

export const arrToArrOfObjects = (arr: any[], prop: string) => {
  const newArr = [];

  arr.forEach((elem) => {
    const newObj = { [prop]: elem };
    newArr.push(newObj);
  });

  return newArr;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.replace(/^./, str[0].toUpperCase());
};

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const randomIntOrZeroOrNull = (number: number) => {
  if (number === 1) return randomIntFromInterval(1, 100);
  else if (number === 2) return 0;
  else return null;
};

export const enumToArray = (enumValue) => {
  return Object.values(enumValue).map((value) => ({ id: value, name: value }));
};

export const transformPropName = (obj, currentPropName: string, newPropName: string) => {
  const { [currentPropName]: propTemp, ...otherProps } = obj;

  return { [newPropName]: propTemp, ...otherProps };
};

export const removeDashsOnDate = (date: string) => date.split('/').join('');

export enum SituationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type SituationStatusString = keyof typeof SituationStatus;

const convertDate = (date, options = {}) => {
  const defaultOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', { ...defaultOptions, ...options });
};

export const formatUpdateInformationDate = (date) =>
  convertDate(new Date(date), {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

export const formatInformationDate = (date: string) =>
  convertDate(new Date(`${date}T12:00:00Z`), { month: 'short' });

export const formatInformationDateFullMonth = (date: string) =>
  convertDate(new Date(`${date}T12:00:00Z`));

export const formatOnlyDate = (date: string, pattern: string = 'dd/MM/y') => {
  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, pattern);
};

export const getInfoOrDefault = (info: string) => info || '-';

export const formatNumber = (value: number) => {
  const formatFloat = (num: number) => (Math.trunc(num * 100) / 100).toFixed(2);

  return Number(Number.isInteger(value) ? value : formatFloat(value));
};

export const formatDatesToView = (date: string) => {
  const yearNumber = date.slice(0, 4);
  const monthNumber = date.slice(5, 7);
  const dayNumber = date.slice(8, 10);
  const time = date.slice(11, 19);

  return `${AbbreviatedMonths[Number(monthNumber) - 1]} ${dayNumber}/${yearNumber} - ${time}`;
};
