import { IconButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const ButtonBackNavigation = styled(IconButton, {
  marginRight: '$4',
});

const ButtonsWrapper = styled('div', {
  marginLeft: 'auto',
  display: 'flex',
  gap: '$4',
});

const AppHeaderWrapper = styled('div', {
  variants: {
    isVisible: {
      false: {
        display: 'none',
      },
      true: {
        display: 'contents',
      },
    },
  },
});

const StyledLink = styled('a', {
  cursor: 'pointer',
});

const AlertsWrapper = styled('div', {
  marginLeft: 'auto',
  display: 'flex',
  gap: '$4',
});

export { AlertsWrapper, AppHeaderWrapper, ButtonBackNavigation, ButtonsWrapper, StyledLink };
