import { useLogService as logService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import { CAPACITY_BFF_PATH, PARAMETERS_ROUTES, SUCCESS_CODE } from '../../constants';
import {
  GetParametersEmployeeTransitionResponse,
  GetParametersResponse,
} from './ParameterService.types';

const log = logService();

export const getParameters = async (): Promise<GetParametersResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${PARAMETERS_ROUTES.parameters}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-parameters-error', response);
      reject(response);
    }
  });
};

export const updateParameters = async (key: string, value: string): Promise<void> => {
  const body = { key, value };
  const response = await Api.patch({
    url: `${CAPACITY_BFF_PATH}${PARAMETERS_ROUTES.parameters}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('update-parameters-error', response);
      reject(response);
    }
  });
};

export const getParametersEmployeeTransition =
  async (): Promise<GetParametersEmployeeTransitionResponse> => {
    const response = await Api.get({
      url: `${CAPACITY_BFF_PATH}${PARAMETERS_ROUTES.parametersEmployeeTransition}`,
    });

    return new Promise((resolve, reject) => {
      if (SUCCESS_CODE.includes(response.status)) {
        resolve(response.data);
      } else {
        log.error('get-parameters-employee-transition-error', response);
        reject(response);
      }
    });
  };
