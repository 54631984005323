import { Button, Select } from '@hexa-ui/components';
import { HTTP_STATUS_CODE } from 'constants/rest/statusCodes';
import { useDialogContext, useToastContext } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import { updateEmployeeToInTransition } from 'services/employee/EmployeeService';
import { EmployeeTransitionRequest } from 'services/employee/EmployeeService.types';
import { getParametersEmployeeTransition } from 'services/parameter/ParameterService';
import { getStructures } from 'services/structure/StructureService';
import { StructureView } from 'services/structure/StructureService.types';
import { useEmployeeFormStore } from 'stores';
import { generateOptions } from 'utils/formik';
import useSuccessInTransitionDialog from '../useSuccessInTransitionDialog';
import { AttentionText, StyledParagraph, StyledWrapper } from './styles';

const DialogContent = ({ hideDialog }: { hideDialog: () => void }) => {
  const { employeeId, setIsInTransition } = useEmployeeFormStore();
  const { showToast } = useToastContext();
  const { showSuccessInTransitionDialog } = useSuccessInTransitionDialog();

  const [structuresList, setStructuresList] = useState<StructureView[]>([]);
  const [selectedStructure, setSelectedStructure] = useState<string>('');
  const [showOptionIDontKnow, setShowOptionIDontKnow] = useState<boolean>(false);

  const renderStructureSelectOptions = useCallback(() => {
    return (
      <>
        {showOptionIDontKnow && (
          <Select.Option value={'-'} data-testid={`i-dont-know-option`}>
            I don't know
          </Select.Option>
        )}
        {structuresList.map(generateOptions)}
      </>
    );
  }, [structuresList, showOptionIDontKnow]);

  const handleOnClickOkContinue = async () => {
    if (employeeId && selectedStructure) {
      try {
        let transitionData: EmployeeTransitionRequest = {
          employeeId: Number(employeeId),
          structureId: Number(selectedStructure),
        };

        if (selectedStructure === '-') {
          transitionData = { employeeId: Number(employeeId) };
        }

        const response = await updateEmployeeToInTransition({ ...transitionData });

        if (response.status === HTTP_STATUS_CODE.CODE_200) {
          showSuccessInTransitionDialog();
          setIsInTransition(true);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message;

        showToast({
          message: errorMessage || 'An unexpected error occurred',
          type: 'error',
        });
      }
    }
  };

  const fetchStructures = useCallback(async () => {
    const response = await getStructures();

    setStructuresList(response?.structures);
  }, []);

  const fetchShowOptionIDontKnow = useCallback(async () => {
    const response = await getParametersEmployeeTransition();

    setShowOptionIDontKnow(response?.showOptionNoStructure);
  }, []);

  useEffect(() => {
    fetchStructures();
    fetchShowOptionIDontKnow();
  }, []);

  return (
    <>
      <StyledParagraph>
        <b>
          This person is now in POD Transition process, in order to continue you need to select
          which Structure they are moving to
        </b>
      </StyledParagraph>

      <Select.Root
        data-testid="select-structure"
        placeholder="Select the structure..."
        label="Inform the structure to which the person will be transitioned"
        width={'100%'}
        multiple={false}
        onChange={(value: string) => setSelectedStructure(value)}
      >
        {renderStructureSelectOptions()}
      </Select.Root>
      <StyledParagraph size="basis">
        The new Structure Guardian will be notified about this request and can allocate this person
        correctly to their new POD. Until that happens, this person will continue to be allocated in
        their former POD.
      </StyledParagraph>
      <AttentionText size="small">
        Attention: Personal Information data that was changed before the action will not be saved.
      </AttentionText>
      <StyledWrapper>
        <Button
          data-testid="pod-transition-cancel-button"
          variant="secondary"
          onClick={hideDialog}
          type="button"
        >
          Back to edit
        </Button>
        <Button
          data-testid="pod-transition-ok-button"
          variant="primary"
          type="button"
          onClick={handleOnClickOkContinue}
          disabled={!selectedStructure}
        >
          Ok, Continue
        </Button>
      </StyledWrapper>
    </>
  );
};

const usePodTransitionDialog = () => {
  const { showDialog, hideDialog } = useDialogContext();

  const showPodTransitionDialog = () => {
    showDialog({
      title: 'POD Transition',
      content: <DialogContent hideDialog={hideDialog} />,
      contentCss: { marginBottom: '0' },
    });
  };
  return { showPodTransitionDialog };
};

export default usePodTransitionDialog;
