import { SidebarItem } from 'admin-portal-shared-services/dist/store/Sidebar/SidebarService.types';
import {
  CategoryAddIcon,
  ChartTreeIcon,
  ConnectIcon,
  HomeIcon,
  PackageIcon,
  ReceiptIcon,
  SettingsIcon,
  SocialIcon,
  TreeDiagramIcon,
  UserSettingsIcon,
  UsersIcon,
} from 'assets/icons';
import { BASE_NAME } from 'constants/appRoutes';
import { SCOPES } from 'constants/scopes';

interface SidebarWithAccess extends SidebarItem {
  scopes?: string[];
}

export const siderbarItems: SidebarWithAccess[] = [
  {
    id: 'home-item',
    icon: HomeIcon,
    title: 'Home',
    path: `${BASE_NAME}`,
  },
  {
    id: 'pod-management-item',
    icon: SocialIcon,
    title: 'POD Management',
    path: `${BASE_NAME}/pod`,
    scopes: [SCOPES.TEAM_READ],
  },
  {
    id: 'vertical-management-item',
    icon: TreeDiagramIcon,
    title: 'Vertical Management',
    path: `${BASE_NAME}/vertical`,
    scopes: [SCOPES.VERTICAL_READ],
  },
  {
    id: 'structure-management-item',
    icon: PackageIcon,
    title: 'Structure Management',
    path: `${BASE_NAME}/structure`,
    scopes: [SCOPES.STRUCTURE_READ],
  },
  {
    id: 'value-stream-management-item',
    icon: ChartTreeIcon,
    title: 'Value Stream Management',
    path: `${BASE_NAME}/value-stream`,
    scopes: [SCOPES.VALUE_STREAM_READ],
  },
  {
    id: 'squad-management-item',
    icon: CategoryAddIcon,
    title: 'Squad Management',
    path: `${BASE_NAME}/squad`,
  },
  {
    id: 'positions-management-item',
    icon: ConnectIcon,
    title: 'Positions Management',
    path: `${BASE_NAME}/position`,
  },
  {
    id: 'people-management-item',
    icon: UsersIcon,
    title: 'People Management',
    path: `${BASE_NAME}/person`,
    scopes: [SCOPES.PEOPLE_READ_ALL, SCOPES.PEOPLE_READ_SELF],
  },
  {
    id: 'capacity-item',
    icon: ReceiptIcon,
    title: 'Capacity Management',
    path: `${BASE_NAME}/capacity`,
    scopes: [SCOPES.CAPACITY_MANAGEMENT_READ],
  },
  {
    id: 'admin-item',
    icon: SettingsIcon,
    title: 'Admin',
    path: `${BASE_NAME}/admin`,
    scopes: [SCOPES.ADMIN_READ],
  },
  {
    id: 'devs-item',
    icon: UserSettingsIcon,
    title: 'Devs',
    path: `${BASE_NAME}/devs`,
    scopes: [SCOPES.DEVELOPER_READ],
  },
];
