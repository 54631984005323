import { useState } from 'react';
import { useRequestReducer } from 'utils/request-reducer';

const useFetch = <T, U>(callback: (data?: U) => Promise<T>) => {
  const [state, dispatch] = useRequestReducer<T>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = (data?: U) => {
    setIsLoading(true);
    dispatch({ type: 'started' });

    callback(data)
      .then((response: T) => {
        dispatch({ type: 'success', response });
      })
      .catch((error) => {
        dispatch({ type: 'error', error });
      })
      .finally(() => setIsLoading(false));
  };

  const resetState = () => {
    dispatch({ type: 'reset' });
  };

  return { state, fetchData, resetState, isLoading };
};

export default useFetch;
