import { Button } from '@hexa-ui/components';
import { useDialogContext } from 'providers';
import usePodTransitionDialog from '../usePodTransitionDialog';
import { StyledParagraph } from './styles';

interface DialogProps {
  handleDeleteTeam: () => void;
  handleAllPodsRemoved: () => void;
}

const useAllocateEmployeeWarningDialog = () => {
  const { showDialog, hideDialog } = useDialogContext();
  const { showPodTransitionDialog } = usePodTransitionDialog();

  const showAllocateEmployeeWarningDialog = ({
    handleDeleteTeam,
    handleAllPodsRemoved,
  }: DialogProps) => {
    const handleOnClickYes = () => {
      handleDeleteTeam();
      hideDialog();
      handleAllPodsRemoved();
    };

    const handleOnClickNo = () => {
      showPodTransitionDialog();
    };

    showDialog({
      title: 'Warning',
      content: (
        <>
          <StyledParagraph>
            This person cannot remain unallocated. Are you planning to allocate this person to a new
            POD?
          </StyledParagraph>
          <StyledParagraph>
            <b>Attention</b>: If this person is being terminated, cancel this action and click on{' '}
            <b>Inactivate</b>.
          </StyledParagraph>
        </>
      ),
      stackedActions: true,
      actions: (
        <>
          <Button
            data-testid="allocation-cancel-button"
            variant="secondary"
            onClick={hideDialog}
            type="button"
          >
            Cancel
          </Button>
          <Button
            data-testid="allocation-yes-button"
            variant="secondary"
            type="button"
            onClick={handleOnClickYes}
          >
            Yes
          </Button>
          <Button
            data-testid="allocation-no-button"
            variant="primary"
            type="button"
            onClick={handleOnClickNo}
          >
            No
          </Button>
        </>
      ),
    });
  };

  return { showAllocateEmployeeWarningDialog };
};

export default useAllocateEmployeeWarningDialog;
