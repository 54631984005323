import { generatePath } from 'react-router-dom';

export const VALUE_STREAM_TYPES_ROUTES = {
  valueStreamTypes: '/valueStreamTypes',
  valueStreamTypeExists: '/valueStreamTypes/exists',
  valueStreamTypesById: {
    route: '/valueStreamTypes/:valueStreamId',
    generate(valueStreamId) {
      return generatePath(this.route, { valueStreamId });
    },
  },
  typesHasValueStreams: {
    route: '/valueStreamTypes/:valueStreamId/hasValueStreams',
    generate(valueStreamId) {
      return generatePath(this.route, { valueStreamId });
    },
  },
  valueStreamTypesTotalization: '/valueStreamTypes/withTotalization',
  valueStreamsByValueStreamTypes: {
    route: '/valueStreamTypes/:valueStreamId/valueStreams',
    generate(valueStreamId) {
      return generatePath(this.route, { valueStreamId });
    },
  },
};
