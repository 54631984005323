import { EmployeeView } from 'services/employee/EmployeeService.types';
import { VerticalView } from 'services/vertical/VerticalService.types';

export enum StructureStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type StructureStatusString = keyof typeof StructureStatus;

export type StructureView = {
  id: number;
  name: string;
  situationStatus?: StructureStatusString;
  vertical?: VerticalView;
  director?: EmployeeView;
  guardians?: Guardian[];
};

export type StructurePaginationView = {
  id: number;
  name: string;
  vertical: string;
  valueStreamAmount: number;
  teamAmount: number;
  situationStatus: StructureStatusString;
};

export type GetStructuresRequest = {
  verticalId: number;
  props?: string[];
};

export type GetStructuresResponse = {
  structures: StructureView[];
};

export type GetStructuresByVerticalsRequest = {
  verticalId?: string[];
};

export type StructurePaginationFilters = {
  names?: string[];
  verticals?: string[];
  status?: StructureStatus[];
};

export type StructurePaginationResponse = {
  pagination: Pagination;
  filters: StructurePaginationFilters;
  structures: StructurePaginationView[];
};

export type CreateStructureRequest = {
  name: string;
  verticalId: number;
  structureId: number;
};

export type UpdateStructureRequest = {
  id: number;
  name: string;
  verticalId: number;
  directorId: number;
  situationStatus: StructureStatusString;
  guardianIds?: number[];
};

export type StructureHasValueStreamsResponse = {
  hasValueStreams: boolean;
};
