import AppHeader from 'components/AppHeader';
import { RouterServiceProvider } from 'providers/RouterServiceProvider';
import { BrowserRouter } from 'react-router-dom';
import Router from '../../routes/Router';
import { StyledGrid } from './styles';

const SidebarConfigProvider = (): JSX.Element => {
  return (
    <StyledGrid type="fluid" sidebar>
      <BrowserRouter>
        <RouterServiceProvider>
          <AppHeader />
          <Router />
        </RouterServiceProvider>
      </BrowserRouter>
    </StyledGrid>
  );
};

export { SidebarConfigProvider };
