import { Button, Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const ContainerIconLineManager = styled('div', {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '$1',
});

const visibilityConfig = {
  variants: {
    isVisible: {
      false: {
        display: 'none',
      },
      true: {
        display: 'flex',
      },
    },
  },
};

const scrollStyled = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '$neutral30',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.55)',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0, 0, 0, 0.55)',
  },
};

const VisibleButton = styled(Button, {
  ...visibilityConfig,
});

const VisibleContainer = styled('div', {
  ...visibilityConfig,
});

const FiltersWrapper = styled('div', {
  padding: '$4 !important',
  width: '100%',
  display: 'flex',
  background: '$neutral20',
  flexDirection: 'column',
  gap: '$2',
  borderBottom: '1px solid $interfaceSurfaceSecondary',
});

const BadgesWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '$2',
  maxHeight: '70px',
  overflowY: 'auto',
});

const SelectedFiltersWrapper = styled('div', {
  display: 'flex',
  flex: 1,
  alignItems: 'baseline',
  ...visibilityConfig,
});

const SearchWrapper = styled(Grid.Item, {
  padding: '24px 24px 16px 24px !important',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  variants: {
    hasAlert: {
      true: {
        padding: '24px 24px 12px 24px !important',
      },
    },
  },
});

const ConsultButtonWrapper = styled(Grid.Item, {
  paddingLeft: '36px !important',
  paddingTop: '16px !important',
  paddingBottom: '10px !important',
});

const TableButtonsContainer = styled(VisibleContainer, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export {
  BadgesWrapper,
  ConsultButtonWrapper,
  ContainerIconLineManager,
  FiltersWrapper,
  SearchWrapper,
  SelectedFiltersWrapper,
  TableButtonsContainer,
  VisibleButton,
  VisibleContainer,
  scrollStyled,
  visibilityConfig,
};
