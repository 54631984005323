import _ from 'lodash';
import { EmployeeAllocation } from 'services/employee/EmployeeService.types';

interface VerifyAllocationsProps {
  initialAllocationsList: EmployeeAllocation[];
  currentAllocationsList: EmployeeAllocation[];
  setAllocationsIsDirty: (allocationsHaveBeenEdited: boolean) => void;
}

export const verifyAllocationsChanges = ({
  initialAllocationsList,
  currentAllocationsList,
  setAllocationsIsDirty,
}: VerifyAllocationsProps) => {
  const formatListObject = (list: EmployeeAllocation[]) =>
    list?.map((item) => {
      return {
        id: item.teamId,
        name: item.teamName,
        currentAllocationPercentage: item.percentage,
        lastMonthPercentage: item?.lastMonthPercentage || 0,
      };
    });

  const initialList = formatListObject(initialAllocationsList);
  const finalList = formatListObject(currentAllocationsList);

  const areListsEqual = _.isEqual(_.sortBy(initialList), _.sortBy(finalList));

  if (areListsEqual) {
    return setAllocationsIsDirty(false);
  }

  setAllocationsIsDirty(true);
};
