import { Select } from '@hexa-ui/components';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { EmployeeStatusString } from 'services/employee/EmployeeService.types';
import { SituationStatus, SituationStatusString } from 'utils/generic';

type OptionProps = {
  id: number | string;
  name: string;
  situationStatus?: SituationStatusString | EmployeeStatusString;
};

const isInputDirty = (formik: ReturnType<typeof useFormik>, name: string) => {
  let boolean = formik.getFieldMeta(name).value === formik.getFieldMeta(name).initialValue;
  return !boolean;
};

const formikErrorsToString = (
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[]
) => (Array.isArray(errors) ? (errors[0] as string) : (errors as string));

const handleFormikErrors = (
  touched: boolean | FormikTouched<any> | FormikTouched<any>[],
  errors: string | string[] | FormikErrors<any> | FormikErrors<any>[],
  dirty: boolean
) => (touched && errors && dirty ? formikErrorsToString(errors) : '');

const getInputProps = (
  inputName: string,
  type: 'input' | 'select' | 'toggle',
  formik: ReturnType<typeof useFormik>
) => {
  switch (type) {
    case 'input':
      return {
        ...formik.getFieldProps(inputName),
        onBlur: (event) => {
          const valueTrimmered = event.target.value.trim();
          formik.setFieldValue(inputName, valueTrimmered);
          formik.setFieldTouched(inputName, true);
        },
        errorText: handleFormikErrors(
          formik.touched[inputName],
          formik.errors[inputName],
          isInputDirty(formik, inputName)
        ),
        hasError:
          !!formik.touched[inputName] &&
          !!formik.errors[inputName] &&
          isInputDirty(formik, inputName),
      };
    case 'select':
      return {
        ...formik.getFieldProps(inputName),
        error: handleFormikErrors(
          formik.touched[inputName],
          formik.errors[inputName],
          isInputDirty(formik, inputName)
        ),
        onChange: (value) => formik.setFieldValue(inputName, value),
      };
    case 'toggle':
      return {
        ...formik.getFieldProps(inputName),
        onCheckedChange: (value: boolean) => formik.setFieldValue(inputName, value),
        checked: formik.values[inputName],
      };
    default:
      throw Error('Must select a type');
  }
};

const generateOptions = ({ id, name, situationStatus }: OptionProps) => {
  const disabledOption = situationStatus ? situationStatus === SituationStatus.Inactive : false;

  return (
    <Select.Option
      key={id}
      value={id.toString()}
      data-testid={`group-option-${id}`}
      disabled={disabledOption}
    >
      {name}
    </Select.Option>
  );
};

export { generateOptions, getInputProps };
