import { useLogService as logService } from 'admin-portal-shared-services';
import { CAPACITY_BFF_PATH, SUCCESS_CODE, TEAM_ROUTES, VALUE_STREAMS_ROUTES } from 'constants/rest';
import qs from 'qs';
import Api from '../../Api/Api';
import {
  CreateTeamRequest,
  GetTeamsByValueStreamRequest,
  GetTeamsRequest,
  GetTeamsReviewByIdResponse,
  GetTeamsReviewResponse,
  PatchGuardianTeamResponse,
  TeamConfirmationResponse,
  TeamLastAllocationUpdateResponse,
  TeamPaginationFilters,
  TeamPaginationResponse,
  TeamsHasEmployeesResponse,
  UpdateTeamRequest,
  updatedTeamGuardianRequest,
} from './TeamService.types';

const log = logService();

export const getTeams = async <GetTeamsResponse>({
  props = [],
}: GetTeamsRequest = {}): Promise<GetTeamsResponse> => {
  const config = {
    params: {
      props,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  };

  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teams}`,
    config,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-teams-error', response);
      reject(response);
    }
  });
};

export const getTeamsWithPagination = async (
  pagination: Pagination,
  filters: TeamPaginationFilters = {}
): Promise<TeamPaginationResponse> => {
  const body = {
    filters,
    pagination,
  };

  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamsPagination}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-teams-pagination-error', response);
      reject(response);
    }
  });
};

export const createTeam = async <TeamView>(newTeam: CreateTeamRequest): Promise<TeamView> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teams}`,
    body: newTeam,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-create-new-team-error', response);
      reject(response);
    }
  });
};

export const teamExists = async (name: string): Promise<boolean> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamsExists}?name=${encodeURIComponent(name)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.exists);
    } else {
      log.error('get-verify-team-error', response);
      reject(response);
    }
  });
};

export const teamsExistsByJiraKey = async (jiraKey: string): Promise<boolean> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamsExistsByJiraKey}?jiraKey=${encodeURIComponent(
      jiraKey
    )}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.exists);
    } else {
      log.error('get-verify-jiraKey-error', response);
      reject(response);
    }
  });
};

export const teamsExistsByJiraTeamName = async (jiraTeamName: string): Promise<boolean> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${
      TEAM_ROUTES.teamsExistsByJiraTeamName
    }?jiraTeamName=${encodeURIComponent(jiraTeamName)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.exists);
    } else {
      log.error('get-verify-jiraTeamName-error', response);
      reject(response);
    }
  });
};

export const deleteTeam = async (teamId: number): Promise<void> => {
  const response = await Api.delete({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamById.generate(teamId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response);
    } else {
      log.error('delete-team-error', response);
      reject(response);
    }
  });
};

export const getTeamById = async <TeamView>(teamId: number): Promise<TeamView> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamById.generate(teamId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-team-error', response);
      reject(response);
    }
  });
};

export const updateTeam = async <TeamView>(updatedTeam: UpdateTeamRequest): Promise<TeamView> => {
  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teams}`,
    body: updatedTeam,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('update-team-error', response);
      reject(response);
    }
  });
};

export const updateTeamGuardian = async (
  updatedTeamGuardian: updatedTeamGuardianRequest
): Promise<PatchGuardianTeamResponse> => {
  const response = await Api.patch({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamById.generate(updatedTeamGuardian.teamId)}`,
    body: updatedTeamGuardian,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('update-team-guardian-error', response);
      reject(response);
    }
  });
};

export const getTeamsByValueStream = async <GetTeamsByValueStreamResponse>({
  pagination,
  valueStreamId,
}: GetTeamsByValueStreamRequest): Promise<GetTeamsByValueStreamResponse> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${VALUE_STREAMS_ROUTES.teamsByValueStream.generate(valueStreamId)}`,
    body: {
      pagination,
    },
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-teams-by-value-stream-error', response);
      reject(response);
    }
  });
};

export const teamsHasEmployees = async (teamId: number): Promise<TeamsHasEmployeesResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamsHasEmployees.generate(teamId)}`,
  });
  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-teams-has-employees-error', response);
      reject(response);
    }
  });
};

export const getConfirmationStatus = async <
  GetConfirmationStatusParameters
>(): Promise<GetConfirmationStatusParameters> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamConfirmationStatus}`,
  });
  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.confirmationStatus);
    } else {
      log.error('get-team-confirmation-status-error', response);
      reject(response);
    }
  });
};

export const getTeamDataConfirmation = async (
  teamId: number
): Promise<TeamConfirmationResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamDataConfirmation.generate(teamId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-team-data-confirmation-error', response);
      reject(response);
    }
  });
};

export const updateTeamDataConfirmation = async (
  teamId: number
): Promise<TeamConfirmationResponse> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamDataConfirmation.generate(teamId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-team-data-confirmation-error', response);
      reject(response);
    }
  });
};

export const getTeamLastAllocationUpdate = async (
  teamId: number
): Promise<TeamLastAllocationUpdateResponse | null> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamLastAllocationUpdate.generate(teamId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-team-last-allocation-update-error', response);
      reject(response);
    }
  });
};

export const getTeamsReview = async (): Promise<GetTeamsReviewResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamsReview}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-teams-review-error', response);
      reject(response);
    }
  });
};

export const getTeamsReviewById = async (teamId: number): Promise<GetTeamsReviewByIdResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${TEAM_ROUTES.teamsReviewById.generate(teamId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-teams-review-by-id-error', response);
      reject(response);
    }
  });
};
