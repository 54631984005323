import { IconButton, Paragraph } from '@hexa-ui/components-latest';
import { useHasPermission } from 'admin-portal-shared-services';
import { TrashIcon } from 'assets/icons';
import Loading from 'components/Loading';
import { BASE_NAME, STATUS } from 'constants/';
import { SCOPES } from 'constants/scopes';
import useFetch from 'hooks/useFetch';
import { useToastContext } from 'providers';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getAllocationsByEmployeeId,
  getEmployeesLastMonthAvailable,
  updateAllocationsByEmployeeId,
} from 'services/employee/EmployeeService';
import { EmployeeStatusString } from 'services/employee/EmployeeService.types';
import { useEmployeeFormStore } from 'stores';
import useAllocateEmployeeWarningDialog from '../hooks/useAllocateEmployeeWarningDialog';
import AllocationsEmpty from './components/AllocationsEmpty';
import AllocationsFooter from './components/AllocationsFooter';
import Search from './components/Search';
import { COLUMNS_NAMES } from './settings';
import {
  Alert,
  AllocationInput,
  AllocationRow,
  AllocationsHeader,
  AllocationsWrapper,
  Container,
  HeaderCell,
} from './styles';
import { verifyAllocationsChanges } from './verifyAllocationsChanges';

const MUST_BE_ONE_ERROR_MESSAGE =
  'The sum of allocation values must be 1. Please, recalculate or edit values';
const SUM_ALLOCATION_ERROR_MESSAGE = (allocationType: string) =>
  `Invalid sum of values. The sum of ${allocationType} values must to be less than or equal to 1`;
const MIN_LAST_MONTH_ALLOCATION_ERROR_MESSAGE = (minValue: number) =>
  `Last Month Allocation must be between ${minValue} and 1`;
const TRANSITION_WARNING_MESSAGE =
  'This person is in POD Transition. Please relocate to a new POD.';
const ALL_PODS_REMOVED_ERROR_TEXT =
  'All PODs are removed. Please, choose a new POD to allocate the person in.';

const ZERO_ALLOCATION = 0;
const FULL_ALLOCATION = 1;

interface AllocationsProps {
  employeeData?: any;
  setEmployeeData?: Dispatch<any>;
  goToNextStep?: () => void;
  setDisableContinue: Dispatch<boolean>;
}

interface EmployeeLocationDataProps {
  isExternal: true;
  situationStatus: EmployeeStatusString;
}

const Allocations = ({
  employeeData,
  setEmployeeData,
  goToNextStep,
  setDisableContinue,
}: AllocationsProps) => {
  const {
    showLastMonthAllocation,
    employeeAllocations,
    getTotalAllocation,
    canNotAddOrEditLessThanOne,
    isEmployeeFromExternalCompany,
    setEmployeeAllocations,
    setShowLastMonthAllocation,
    setHasDeletedPod,
    setHasPermissionToAllocateLessThanOne,
    getTotalLastMonthAllocation,
    allocationsIsDirty,
    setAllocationsIsDirty,
    setIsEmployeeFromExternalCompany,
    setHasAllocationWarning,
    setEmployeeId,
    setIsInTransition,
    isInTransition,
    successDialogHasBeenClosed,
    setSuccessDialogHasBeenClosed,
    isReactivating,
  } = useEmployeeFormStore();

  const { employeeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToastContext();
  const { showAllocateEmployeeWarningDialog } = useAllocateEmployeeWarningDialog();

  const employee = location.state as EmployeeLocationDataProps;

  const hasPermissionToNoAllocation = useHasPermission(SCOPES.PEOPLE_NO_ALLOCATION_WRITE);
  const hasPermissionToAllocateLessThanOne = useHasPermission(
    SCOPES.PEOPLE_ALLOCATION_LESS_THAN_ONE
  );

  const lastMonthPercentageInputRef = useRef(null);

  const [currentPercentageError, setCurrentPercentageError] = useState(null);
  const [lastMonthPercentageError, setLastMonthPercentageError] = useState(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [validateLastMonth, setValidateLastMonth] = useState<boolean>(false);
  const [prevAllocations, setPrevAllocations] = useState([]);
  const [allPODsRemovedError, setAllPodsRemovedError] = useState<string>(null);
  const [isEmployeeInactivated, setIsEmployeeInactivated] = useState<boolean>(false); // TODO: https://ab-inbev.atlassian.net/browse/BEESMGTO-6686

  const transitionsError =
    !isEmployeeInactivated && isEditing && (!!allPODsRemovedError || isInTransition);

  const {
    state: allocationsState,
    fetchData: getAllocations,
    isLoading: isLoadingAllocations,
  } = useFetch(getAllocationsByEmployeeId);

  const { state: lastMonthState, fetchData: getLastMonthPeriod } = useFetch(
    getEmployeesLastMonthAvailable
  );

  const {
    state: updatedAllocations,
    fetchData: updateAllocations,
    isLoading: isUpdatingAllocations,
  } = useFetch(updateAllocationsByEmployeeId);

  const allocationHasChanged = prevAllocations !== employeeAllocations;
  const tableListHasChanged = allocationsIsDirty || allocationHasChanged;

  const renderTableHeaderCells = () =>
    COLUMNS_NAMES.map((column, idx) => {
      const hideLastMonth = !showLastMonthAllocation && column === 'Last Allocation';
      return (
        <HeaderCell key={column} className={idx === 0 && 'text'}>
          {!hideLastMonth && column}
        </HeaderCell>
      );
    });

  const handleRemoveOnClick = (removedIndex) => {
    const filterByRemoved = employeeAllocations.filter((_, index) => index !== removedIndex);

    if (isEditing) {
      const isTheLastAllocation = employeeAllocations.length === 1;
      const employeeHasPrevAllocation = !!prevAllocations.length;
      const employeeIsInTransitionStatus = employee?.situationStatus === STATUS.InTransition;

      const canShowWarningAllocatioDialog =
        !hasPermissionToNoAllocation && isTheLastAllocation && !employeeIsInTransitionStatus;

      if (employeeHasPrevAllocation && canShowWarningAllocatioDialog) {
        showAllocateEmployeeWarningDialog({
          handleDeleteTeam: () => setEmployeeAllocations(filterByRemoved),
          handleAllPodsRemoved: () => setAllPodsRemovedError(ALL_PODS_REMOVED_ERROR_TEXT),
        });
        return;
      }
    }

    setEmployeeAllocations(filterByRemoved);
    setHasDeletedPod(true);
  };

  const canNotAddOrEditLessThanOneValue = canNotAddOrEditLessThanOne();

  const handleErrorMessage = useCallback(
    (allocation: number) => {
      const canNotAllocateLessThanOne =
        canNotAddOrEditLessThanOneValue && allocation !== FULL_ALLOCATION;
      const allocationIsZero = allocation === ZERO_ALLOCATION;

      if (canNotAllocateLessThanOne) return MUST_BE_ONE_ERROR_MESSAGE;
      else if (allocationIsZero && isEmployeeFromExternalCompany && !hasPermissionToNoAllocation) {
        return SUM_ALLOCATION_ERROR_MESSAGE('allocation');
      } else if (employeeAllocations.length > 0 && allocationIsZero) {
        return 'Allocation cannot be less than 0.01';
      } else if (
        isEditing &&
        allocation === ZERO_ALLOCATION &&
        !hasPermissionToAllocateLessThanOne
      ) {
        return setAllPodsRemovedError(ALL_PODS_REMOVED_ERROR_TEXT);
      } else if (allocation > FULL_ALLOCATION) return SUM_ALLOCATION_ERROR_MESSAGE('allocation');
      else return null;
    },
    [canNotAddOrEditLessThanOneValue, isEmployeeFromExternalCompany, employeeAllocations]
  );

  const lastMonthValue = lastMonthPercentageInputRef.current?.value;

  const handleLastMonthErrorMessage = useCallback(() => {
    const thereIsSomeWithZeroValue =
      employeeAllocations.length > 0 &&
      employeeAllocations.some((allocation) => allocation?.lastMonthPercentage == ZERO_ALLOCATION);

    if (!isEditing && lastMonthValue === '') return null;

    if (getTotalLastMonthAllocation() > FULL_ALLOCATION || lastMonthValue > FULL_ALLOCATION) {
      return SUM_ALLOCATION_ERROR_MESSAGE('last month allocation');
    } else if (!isEditing && (lastMonthValue <= ZERO_ALLOCATION || thereIsSomeWithZeroValue)) {
      return MIN_LAST_MONTH_ALLOCATION_ERROR_MESSAGE(0.01);
    } else if (isEditing && validateLastMonth && lastMonthValue === '') {
      return MIN_LAST_MONTH_ALLOCATION_ERROR_MESSAGE(0);
    } else return null;
  }, [
    isEditing,
    lastMonthValue,
    getTotalLastMonthAllocation,
    employeeAllocations,
    validateLastMonth,
  ]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (isEditing && !isReactivating) {
      updateAllocations({
        employeeId,
        allocations: employeeAllocations,
      });
      return;
    }

    setEmployeeData({ ...employeeData, allocations: employeeAllocations });
    goToNextStep();
  };

  const onChangeAllocationInput = (event, allocationIndex) => {
    const { name, value } = event.target as HTMLInputElement;

    if (value.match(/(^\d{1}\.\d{0,2}$)|^\d{1}$/) || value === '') {
      if (name === 'lastMonthPercentage') setValidateLastMonth(value === '');

      const updatedAllocations = employeeAllocations.map((allocation, idx) => {
        return idx === allocationIndex ? { ...allocation, [name]: value || null } : allocation;
      });

      setEmployeeAllocations(updatedAllocations);
    }
  };

  const handleErrors = (errorState) => {
    if (errorState?.error) {
      const { error } = errorState;
      showToast({
        type: 'error',
        message: error.response?.data?.message || error?.message || 'An unexpected error occurred',
      });
    }
  };

  useEffect(() => {
    const employeeIsInTransitionStatus = employee?.situationStatus === STATUS.InTransition;
    const allocationHasChanged = prevAllocations !== employeeAllocations && allocationsIsDirty;
    const isEditingEmployeeInTransition = isEditing && employeeIsInTransitionStatus;

    if (isEditingEmployeeInTransition) {
      if (allocationHasChanged) {
        setIsInTransition(false);
        setAllPodsRemovedError(null);
        return;
      }

      setIsInTransition(employeeIsInTransitionStatus);
    }
  }, [isEditing, employee, allocationsIsDirty, prevAllocations, employeeAllocations]);

  useEffect(() => {
    return () => {
      setCurrentPercentageError(null);
      setAllPodsRemovedError(null);
      setLastMonthPercentageError(null);
      setSuccessDialogHasBeenClosed(false);
    };
  }, []);

  useEffect(() => {
    if (successDialogHasBeenClosed) navigate(`${BASE_NAME}/person/${employeeId}`);
  }, [successDialogHasBeenClosed, employeeId]);

  useEffect(() => {
    if (isEditing && employeeAllocations.length > 0) {
      setAllPodsRemovedError(null);
    }
  }, [isEditing, employeeAllocations]);

  useEffect(() => {
    setHasAllocationWarning(!!transitionsError);
  }, [transitionsError]);

  useEffect(() => {
    handleErrors(updatedAllocations);
  }, [updatedAllocations.error]);

  useEffect(() => {
    handleErrors(allocationsState);
  }, [allocationsState.error]);

  useEffect(() => {
    if (isEditing && updatedAllocations?.response) {
      showToast({
        type: 'success',
        message: 'Allocation updated successfully',
      });
      navigate(`${BASE_NAME}/person/${employeeId}`);
    }
  }, [updatedAllocations.response, isEditing]);

  useEffect(() => {
    if (employeeId && !isReactivating) {
      setIsEditing(true);
      setEmployeeId(employeeId);
      getAllocations(Number(employeeId));
    }
  }, [employeeId, isReactivating]);

  useEffect(() => {
    getLastMonthPeriod();
  }, []);

  useEffect(() => {
    if (isEditing && isUpdatingAllocations && !isReactivating) {
      setDisableContinue(isUpdatingAllocations);
    }
  }, [isEditing, isUpdatingAllocations, isReactivating]);

  useEffect(() => {
    if (lastMonthState.response) {
      setShowLastMonthAllocation(lastMonthState.response.lastMonthAvailable);
    }
  }, [lastMonthState]);

  useEffect(() => {
    if (isEditing && allocationsState.response) {
      setEmployeeAllocations(allocationsState.response.allocations);
      setPrevAllocations(allocationsState.response.allocations);
    }
  }, [allocationsState, isEditing]);

  useEffect(() => {
    setHasPermissionToAllocateLessThanOne(hasPermissionToAllocateLessThanOne);
  }, [hasPermissionToAllocateLessThanOne]);

  useEffect(() => {
    setCurrentPercentageError(handleErrorMessage(getTotalAllocation()));
  }, [handleErrorMessage, getTotalAllocation()]);

  useEffect(() => {
    setLastMonthPercentageError(handleLastMonthErrorMessage());
  }, [handleLastMonthErrorMessage]);

  useEffect(() => {
    if (isEditing && employee) {
      setIsEmployeeFromExternalCompany(employee.isExternal);
      setIsInTransition(employee.situationStatus === STATUS.InTransition);
    }
  }, [employee, isEditing]);

  useEffect(() => {
    if (isEditing) {
      verifyAllocationsChanges({
        initialAllocationsList: prevAllocations,
        currentAllocationsList: employeeAllocations,
        setAllocationsIsDirty,
      });
    }
  }, [prevAllocations, employeeAllocations, isEditing]);

  useEffect(() => {
    const hasErrors = !!currentPercentageError || !!lastMonthPercentageError;
    setDisableContinue((isEditing && !isReactivating && !tableListHasChanged) || hasErrors);
  }, [tableListHasChanged, currentPercentageError, lastMonthPercentageError, isEditing]);

  const renderAlertError =
    (currentPercentageError || lastMonthPercentageError) && !transitionsError;

  return (
    <Container data-testid="employee-form-allocations" id="form-2" onSubmit={(e) => onSubmit(e)}>
      <Search />
      {isLoadingAllocations ? (
        <Loading />
      ) : (
        <>
          <AllocationsHeader>{renderTableHeaderCells()}</AllocationsHeader>

          <AllocationsWrapper>
            {!employeeAllocations.length ? (
              <AllocationsEmpty />
            ) : (
              employeeAllocations.map((allocation, index) => {
                return (
                  <AllocationRow key={index}>
                    <Paragraph
                      className={`text ${isInTransition && 'inTransition'}`}
                      weight="semibold"
                    >
                      {allocation.teamName}
                    </Paragraph>
                    {showLastMonthAllocation ? (
                      <AllocationInput
                        name="lastMonthPercentage"
                        data-testid="last-month-allocation-input"
                        size="small"
                        ref={lastMonthPercentageInputRef}
                        placeholder={isEditing ? '0 to 1' : '0.01 to 1'}
                        required
                        value={allocation.lastMonthPercentage}
                        onChange={(event) => onChangeAllocationInput(event, index)}
                      />
                    ) : (
                      <div />
                    )}
                    <AllocationInput
                      name="percentage"
                      data-testid="current-allocation-input"
                      placeholder="0.01 to 1"
                      size="small"
                      required
                      value={allocation.percentage}
                      onChange={(event) => onChangeAllocationInput(event, index)}
                    />
                    <IconButton
                      data-testid="trash-icon"
                      type="button"
                      icon={TrashIcon}
                      size="small"
                      variant="tertiary"
                      onClick={() => handleRemoveOnClick(index)}
                    />
                  </AllocationRow>
                );
              })
            )}
          </AllocationsWrapper>

          {transitionsError && (
            <Alert
              data-testid="alert-warning"
              message={isInTransition ? TRANSITION_WARNING_MESSAGE : allPODsRemovedError}
              type="warning"
            />
          )}

          {renderAlertError && (
            <Alert
              data-testid="alert-error"
              message={currentPercentageError || lastMonthPercentageError}
              type="error"
            />
          )}
          <AllocationsFooter />
        </>
      )}
    </Container>
  );
};

export default Allocations;
