import { generatePath } from 'react-router-dom';

export const TEAM_ROUTES = {
  teams: '/teams',
  teamById: {
    route: '/teams/:teamId',
    generate(teamId) {
      return generatePath(this.route, { teamId });
    },
  },
  teamsPagination: '/teams/filters',
  teamsExists: '/teams/exists',
  teamsExistsByJiraKey: '/teams/existsJiraKey',
  teamsExistsByJiraTeamName: '/teams/existsJiraTeamName',
  employeesByTeam: {
    route: '/teams/:teamId/employees',
    generate(teamId) {
      return generatePath(this.route, { teamId });
    },
  },
  teamsHasEmployees: {
    route: '/teams/:teamId/hasEmployees',
    generate(teamId) {
      return generatePath(this.route, { teamId });
    },
  },
  teamConfirmationStatus: '/parameters/team/confirmationStatus',
  teamDataConfirmation: {
    route: '/teams/:teamId/dataConfirmation',
    generate(teamId) {
      return generatePath(this.route, { teamId });
    },
  },
  teamLastAllocationUpdate: {
    route: '/teams/:teamId/employees/lastAllocationUpdate',
    generate(teamId) {
      return generatePath(this.route, { teamId });
    },
  },
  teamsReview: '/teams/review',
  teamsReviewById: {
    route: '/teams/review/:teamId',
    generate(teamId) {
      return generatePath(this.route, { teamId });
    },
  },
};
