import { EmployeeAllocation } from 'services/employee/EmployeeService.types';
import { normalizeFloatValue } from 'utils/allocation';
import { create } from 'zustand';
import { EmployeeFormActions, EmployeeFormState } from './types';

const initialState: EmployeeFormState = {
  employeeId: null,
  isEditForm: false,
  hasPermissionToAllocateLessThanOne: false,
  employeeAllocations: [],
  allocationsIsDirty: false,
  totalLastMonthAllocationOfEmployee: null,
  percentageError: null,
  currentAllocationPercentageError: null,
  isEmployeeFromExternalCompany: null,
  successDialogHasBeenClosed: false,
  hasAllocationWarning: false,
  showLastMonthAllocation: false,
  hasDeletedPod: false,
  isInTransition: false,
  isReactivating: false,
};

export const useEmployeeFormStore = create<EmployeeFormState & EmployeeFormActions>((set, get) => ({
  ...initialState,
  setEmployeeId: (id) => set({ employeeId: id }),
  setIsEditForm: (isEditForm) => set({ isEditForm }),
  setIsReactivating: (isReactivating) => set({ isReactivating }),
  setHasPermissionToAllocateLessThanOne: (hasPermissionToAllocateLessThanOne) =>
    set({ hasPermissionToAllocateLessThanOne }),
  setEmployeeAllocations: (employeeAllocations: EmployeeAllocation[]) =>
    set({ employeeAllocations }),
  setAllocationsIsDirty: (allocationsIsDirty) => set({ allocationsIsDirty }),
  setTotalLastMonthAllocationOfEmployee: (totalLastMonthAllocationOfEmployee) =>
    set({ totalLastMonthAllocationOfEmployee }),
  setPercentageError: (errorMessage) => set({ percentageError: errorMessage }),
  setCurrentAllocationPercentageError: (errorMessage) =>
    set({ currentAllocationPercentageError: errorMessage }),
  setIsEmployeeFromExternalCompany: (isExternal) =>
    set({ isEmployeeFromExternalCompany: isExternal }),
  setSuccessDialogHasBeenClosed: (hasBeenClosed) =>
    set({ successDialogHasBeenClosed: hasBeenClosed }),
  setShowLastMonthAllocation: (showLastMonthAllocation) => set({ showLastMonthAllocation }),
  setHasDeletedPod: (hasDeletedPod) => set({ hasDeletedPod }),
  setIsInTransition: (isInTransition) => set({ isInTransition }),

  canNotAddOrEditLessThanOne: () => {
    const { hasPermissionToAllocateLessThanOne, isEmployeeFromExternalCompany } = get();

    return !hasPermissionToAllocateLessThanOne && !isEmployeeFromExternalCompany;
  },

  setHasAllocationWarning: (hasWarning: boolean) => set({ hasAllocationWarning: hasWarning }),

  getTotalAllocation: () => {
    const { employeeAllocations } = get();
    return normalizeFloatValue(
      employeeAllocations.reduce((acc, current) => Number(acc) + Number(current.percentage || 0), 0)
    );
  },

  getTotalLastMonthAllocation: () => {
    const { employeeAllocations } = get();
    return normalizeFloatValue(
      employeeAllocations.reduce(
        (acc, current) => Number(acc) + Number(current.lastMonthPercentage || 0),
        0
      )
    );
  },

  handleRecalculateEqually: () => {
    const { employeeAllocations } = get();
    const totalItems = employeeAllocations.length;
    const newPercentage = normalizeFloatValue(1 / totalItems);
    const newPercentageSum = newPercentage * totalItems;
    const sumDiff = normalizeFloatValue(1 - newPercentageSum);

    if (sumDiff !== 0) {
      const updatePercentage = (item: EmployeeAllocation, index: number) => {
        const lastItem = index === totalItems - 1;
        const percentage = lastItem ? normalizeFloatValue(newPercentage + sumDiff) : newPercentage;
        return { ...item, percentage: percentage };
      };
      const updatedAllocations = employeeAllocations.map(updatePercentage);

      set({ employeeAllocations: updatedAllocations });
      return;
    }

    const updatedAllocations = employeeAllocations.map((item) => {
      return { ...item, percentage: newPercentage };
    });

    set({ employeeAllocations: updatedAllocations, hasDeletedPod: false });

    return updatedAllocations;
  },

  handleRecalculateProportionally: () => {
    const { employeeAllocations } = get();
    const currentPercentage = employeeAllocations.reduce(
      (accumulator, item) => Number(accumulator) + Number(item.percentage || 0),
      0
    );

    const updatedAllocations = employeeAllocations.map((item) => {
      return {
        ...item,
        percentage: normalizeFloatValue(Number(item.percentage || 0) / currentPercentage),
      };
    });

    const finalPercentage = updatedAllocations.reduce(
      (accumulator, item) => Number(accumulator) + Number(item.percentage || 0),
      0
    );
    if (finalPercentage !== 1) {
      updatedAllocations[updatedAllocations.length - 1].percentage = normalizeFloatValue(
        updatedAllocations[updatedAllocations.length - 1].percentage + (1 - finalPercentage)
      );
    }

    set({ employeeAllocations: updatedAllocations, hasDeletedPod: false });
  },

  reset: () => set(initialState),
}));
