import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const ListItemWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
});

const ListItemDetailsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ListItemTitle = styled(Paragraph, {
  fontWeight: '$medium !important',
  fontSize: '$4 !important',
});

const ListItemDescription = styled(Paragraph, {
  color: '$neutral70 !important',
  fontSize: '$3 !important',
  marginTop: '-$1 !important',
});

export { ListItemWrapper, ListItemDetailsWrapper, ListItemTitle, ListItemDescription };
