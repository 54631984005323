import { generatePath } from 'react-router-dom';

export const COMPANIES_ROUTES = {
  companies: '/companies',
  companyById: {
    route: '/companies/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  companiesWithTotalization: '/companies/withTotalization',
  companyExists: '/companies/exists',
  companyLocation: '/locations',
  companyHasEmployees: {
    route: '/companies/:id/hasEmployees',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  structuresByCompany: {
    route: '/companies/:id/structures',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  companiesWithTotalizationById: {
    route: '/companies/:id/totalizations',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  structureTotalizations: {
    route: '/companies/:companyId/structures/:structureId/totalizations',
    generate(companyId, structureId) {
      return generatePath(this.route, { companyId, structureId });
    },
  },
};
