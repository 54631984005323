import { useLogService as logService } from 'admin-portal-shared-services';
import { CAPACITY_BFF_PATH, SUCCESS_CODE } from 'constants/rest';
import { USER_ROUTES } from 'constants/rest/routes/user';
import Api from '../../Api/Api';
import { UserUpdateLastLoginResponse, VerifyPermisssionsResponse } from './UserService.types';

const log = logService();

export const updateLastLogin = async (): Promise<UserUpdateLastLoginResponse> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${USER_ROUTES.updateLastLogin}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-update-last-login-error', response);
      reject(response);
    }
  });
};

export const verifyPermissions = async (): Promise<VerifyPermisssionsResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${USER_ROUTES.verifyPermissions}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('verify-email-permissions-error', response);
      reject(response);
    }
  });
};
