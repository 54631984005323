import { saveAs } from 'file-saver';

export const downloadFileFromRoute = (
  response: any,
  fileType: string = undefined,
  defaultFileName?: string
) => {
  const extractFileNameFromHeader = () => {
    if (!response.headers['content-disposition']) return defaultFileName;

    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition.split('=')[1];
    return filename;
  };

  const file = new Blob([response.data], { type: fileType });

  saveAs(file, extractFileNameFromHeader());
};
