import { generatePath } from 'react-router-dom';

export const CAPACITY_ROUTES = {
  capacityPagination: '/capacityManagement/structures/filters',
  capacityForecastHierarchicalStructureByStructure: {
    route: '/capacityManagement/forecast/structures/:structureId/hierarchicalStructure',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  capacityForecastRangeDates: '/capacityManagement/forecast/rangeDates',
  capacityForecastByStructure: {
    route: '/capacityManagement/forecast/structures/:structureId',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  capacityForecastUpdatedInformation: {
    route: '/capacityManagement/forecast/structures/:structureId/updateInformation',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  capacityHistoryHierarchicalStructureByStructure: {
    route: '/capacityManagement/history/structures/:structureId/hierarchicalStructure',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  capacityHistoryRangeDates: '/capacityManagement/history/rangeDates',
  capacityHistoryByStructure: {
    route: '/capacityManagement/history/structures/:structureId',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
  capacityUploadPlanning: '/capacityManagement/upload/planningAllocation',
  capacityExportPlanning: '/capacityManagement/export/planningAllocation',
  capacityExportForecastByStructure: {
    route: '/capacityManagement/export/forecast/structures/:structureId',
    generate(structureId) {
      return generatePath(this.route, { structureId });
    },
  },
};
