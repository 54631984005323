import { useHasPermission } from 'admin-portal-shared-services';
import { PermissionScopes, UserRoles } from 'constants/auth';
import LoadingPage from 'pages/LoadingPage';
import { useToastContext } from 'providers/ToastProvider';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { verifyPermissions } from 'services/user/UserService';

type Auth = {
  roles?: UserRoles[];
  isLoading?: boolean;
  verifyRole: (roleToVerify: UserRoles | UserRoles[]) => boolean;
  userHasDevParametersPermission: boolean;
};

const AuthContext = createContext<Auth>({
  roles: [],
  isLoading: true,
  verifyRole: () => true,
  userHasDevParametersPermission: false,
});

export const AuthProvider = ({ children }) => {
  const { showToast } = useToastContext();
  const isAdmin = useHasPermission(PermissionScopes[UserRoles.ADMIN]);
  const isDirector = useHasPermission(PermissionScopes[UserRoles.DIRECTOR]);
  const isRegularEmployee = useHasPermission(PermissionScopes[UserRoles.EMPLOYEE]);
  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userHasDevParametersPermission, setUserHasDevParametersPermission] =
    useState<boolean>(false);

  const getUserRole = () => {
    const arrforRoles = [];

    if (isAdmin) arrforRoles.push(UserRoles.ADMIN);
    if (isDirector) arrforRoles.push(UserRoles.DIRECTOR);
    if (isRegularEmployee) arrforRoles.push(UserRoles.EMPLOYEE);

    setRoles(arrforRoles);
  };

  const verifyRole = (rolesToVerify: UserRoles | UserRoles[]) =>
    Array.isArray(rolesToVerify)
      ? roles.some((role) => rolesToVerify.includes(role))
      : roles.includes(rolesToVerify);

  const verifyUserPermissionsByEmail = useCallback(async () => {
    try {
      const response = await verifyPermissions();

      setUserHasDevParametersPermission(!!response?.devParameters);
    } catch (error) {
      showToast({
        message: error.message,
        type: 'error',
      });
    }
  }, [verifyPermissions]);

  useEffect(() => {
    verifyUserPermissionsByEmail();
  }, [verifyUserPermissionsByEmail]);

  useEffect(() => {
    getUserRole();
  }, [isAdmin, isDirector, isRegularEmployee]);

  useEffect(() => {
    roles && setIsLoading(false);
  }, [roles]);

  if (isLoading) return <LoadingPage />;

  return (
    <AuthContext.Provider value={{ roles, isLoading, verifyRole, userHasDevParametersPermission }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
