import { EmployeesHistoryRangeDates } from 'services/employee/EmployeeService.types';
import { create } from 'zustand';

interface EmployeeHistoryState {
  currentDates: string[];
  datesQuantity: number;
  datesAvailable: EmployeesHistoryRangeDates[];
  setCurrentDates: (currentDates: string[]) => void;
  setDatesQuantity: (datesQuantity: number) => void;
  setDatesAvailable: (datesAvailable: EmployeesHistoryRangeDates[]) => void;
}

export const useEmployeeHistoryStore = create<EmployeeHistoryState>((set, get) => ({
  currentDates: [],
  datesQuantity: null,
  datesAvailable: [],
  setCurrentDates: (currentDates: string[]) => set({ currentDates }),
  setDatesQuantity: (datesQuantity: number) => set({ datesQuantity }),
  setDatesAvailable: (datesAvailable: EmployeesHistoryRangeDates[]) => set({ datesAvailable }),
}));
