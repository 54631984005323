import { Button } from '@hexa-ui/components-latest';
import SearchAutoComplete from 'components/SearchAutoComplete';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeAllocation } from 'services/employee/EmployeeService.types';
import { getTeams } from 'services/team/TeamService';
import { GetTeamsResponse, TeamView } from 'services/team/TeamService.types';
import { useEmployeeFormStore } from 'stores';
import { Label, SearchBox } from './styles';

const Search = () => {
  const {
    employeeAllocations,
    setCurrentAllocationPercentageError,
    setEmployeeAllocations,
    setAllocationsIsDirty,
    handleRecalculateEqually,
  } = useEmployeeFormStore();
  const [teamsList, setTeamsList] = useState<TeamView[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<EmployeeAllocation | null>(null);
  const [clearInput, setClearInput] = useState<number>(0);

  const selectTeam = (value: TeamView) => {
    const team = {
      id: value.id,
      teamId: value.id,
      teamName: value.name,
      percentage: 0,
      hierarchyInfo: value.hierarchyInfo,
    };

    setSelectedTeam(team);
  };

  const handleAddOnClick = () => {
    const newAllocationList = [...employeeAllocations, selectedTeam];
    setEmployeeAllocations(newAllocationList);
    handleRecalculateEqually();

    setCurrentAllocationPercentageError(null);

    setSelectedTeam(null);

    setClearInput((previous) => previous + 1);
    setAllocationsIsDirty(true);
  };

  const getTeamList = useCallback(async () => {
    try {
      const response: GetTeamsResponse = await getTeams();

      setTeamsList(response?.teams);
    } catch (error) {
      throw new Error('Failed to fetch teams.');
    }
  }, []);

  useEffect(() => {
    getTeamList();
  }, [getTeamList]);

  useEffect(() => {
    const teamId = employeeAllocations?.map((team: EmployeeAllocation) => team.teamId);

    const newListWithoutSelectedTeams = teamsList?.filter(
      (employee: TeamView) => !teamId.includes(employee.id)
    );

    setTeamsList(newListWithoutSelectedTeams);
  }, [employeeAllocations, selectedTeam]);

  return (
    <SearchBox data-testid="search-wrapper">
      <Label weight="semibold">Search and add PODs to allocate the person.</Label>
      <SearchAutoComplete
        data-testid="search-field"
        optionsList={teamsList}
        placeholder="Search"
        size="full"
        optionLabel="name"
        onSelectedItemChange={(value) => selectTeam(value)}
        clearInput={clearInput}
        required
      />
      <Button
        data-testid="add-team"
        disabled={!selectedTeam?.teamId}
        variant="secondary"
        onClick={handleAddOnClick}
        type="button"
        size="large"
      >
        Add
      </Button>
    </SearchBox>
  );
};

export default Search;
