export enum UserRoles {
  ADMIN = 'ADMIN',
  DIRECTOR = 'DIRECTOR',
  EMPLOYEE = 'EMPLOYEE',
  LINE_MANAGER = 'LINE_MANAGER',
}

export enum PermissionGroups {
  ADMIN = 'CapacityManagement.Capacity Management Admin',
  EMPLOYEE = 'CapacityManagement.Capacity Management Employee',
  LINE_MANAGER = 'CapacityManagement.Capacity Management Line Manager',
}

export const PermissionScopes = {
  ADMIN: [
    'CapacityManagement.Team.Read',
    'CapacityManagement.Team.Create',
    'CapacityManagement.Team.Update',
    'CapacityManagement.Team.AddPerson',
    'CapacityManagement.Team.Delete',
    'CapacityManagement.People.ReadAll',
    'CapacityManagement.People.CreateAll',
    'CapacityManagement.People.UpdateAll',
    'CapacityManagement.People.UpdateAllocation',
    'CapacityManagement.People.Delete',
    'CapacityManagement.CapacityManagement.Read',
    'CapacityManagement.CapacityManagement.AdminPlanning',
    'CapacityManagement.Forecast.WritePlan',
    'CapacityManagement.Forecast.WriteValidated',
    'CapacityManagement.Forecast.WriteForecast',
    'CapacityManagement.Forecast.UploadPlan',
    'CapacityManagement.ValueStream.Read',
    'CapacityManagement.ValueStream.Inactivate',
    'CapacityManagement.ValueStream.Create',
    'CapacityManagement.ValueStream.Update',
    'CapacityManagement.ValueStream.Delete',
    'CapacityManagement.Admin.Read',
    'CapacityManagement.Role.Read',
    'CapacityManagement.Role.MovePeople',
    'CapacityManagement.RoleGroup.Create',
  ],
  DIRECTOR: [
    'CapacityManagement.CapacityManagement.Read',
    'CapacityManagement.Forecast.WriteForecast',
  ],
  EMPLOYEE: [
    'CapacityManagement.People.ReadSelf',
    'CapacityManagement.People.CreateSelf',
    'CapacityManagement.People.UpdateSelf',
    'CapacityManagement.People.UpdateAllocation',
  ],
  LINE_MANAGER: [
    'CapacityManagement.Team.Read',
    'CapacityManagement.Team.AddPerson',
    'CapacityManagement.People.ReadAll',
    'CapacityManagement.People.CreateAll',
    'CapacityManagement.People.UpdateAll',
    'CapacityManagement.People.UpdateAllocation',
    'CapacityManagement.People.Delete',
  ],
};
