import { Serializer } from 'miragejs';

let ApplicationSerializer = Serializer.extend({
  root: true,
});

const serializers = {
  application: ApplicationSerializer,
  forSelect: ApplicationSerializer.extend({
    attrs: ['id', 'name'],
  }),
  employeeForSelect: ApplicationSerializer.extend({
    attrs: ['id', 'name', 'email', 'employeeNumber'],
  }),
};

export default serializers;
