import { useState } from 'react';

export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const toggle = () => (isOpen ? closeDialog : openDialog);

  return { isOpen, openDialog, closeDialog, toggle };
};
