import { Alert } from '@hexa-ui/components';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { StyledToastWrapper } from './styles';
import { ToastContextState, ToastProps } from './types';

const initialState: ToastContextState = {
  props: { message: '', type: 'info', position: 'bottom-center', showCloseButton: true },
} as ToastContextState;

const ToastContext = createContext<ToastContextState>(initialState);

export const ToastProvider = ({ children }): JSX.Element => {
  const [props, setProps] = useState(initialState.props);
  const [duration, setDuration] = useState(3000);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) setTimeout(() => setOpen(false), duration);
  }, [open]);

  const showToast = (
    toastData: ToastProps | string = initialState.props,
    toastDuration: number = 3000
  ) => {
    const toastProps =
      typeof toastData === 'string'
        ? { ...initialState.props, message: toastData }
        : { ...initialState.props, ...toastData };

    setProps(toastProps);
    setDuration(toastDuration);
    setOpen(true);
  };

  const hideToast = () => {
    setOpen(false);
  };

  const providerValues = useMemo(
    () => ({
      showToast: showToast,
      hideToast: hideToast,
      props: props,
    }),
    [showToast, hideToast, props]
  );

  return (
    <ToastContext.Provider data-testid="toast-provider" value={providerValues}>
      <StyledToastWrapper open={open} position={props.position}>
        {open && (
          <Alert
            data-testid="toast"
            onClose={hideToast}
            componentVariant="toast"
            message={props.message}
            type={props.type}
            showCloseButton={props.showCloseButton}
            css={{ maxWidth: '80vw', width: 'auto' }}
          />
        )}
      </StyledToastWrapper>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
