import { generatePath } from 'react-router-dom';

export const ROLES_ROUTES = {
  roles: '/roles',
  roleGroups: '/roles/groups',
  roleGroupsWithRoleTotalization: '/roles/groups/withRoleTotalization',
  roleExists: '/roles/exists',
  roleGroupExists: '/roles/groups/exists',
  roleEmployeesChange: '/roles/employees',
  roleGroupsById: {
    route: '/roles/groups/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  rolesById: {
    route: '/roles/:id',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  roleGroupCanInactivate: {
    route: '/roles/groups/:id/canInactivate',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  roleCanInactivate: {
    route: '/roles/:id/canInactivate',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  employeesByRoleId: {
    route: '/roles/:id/employees',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
  rolesHasEmployees: {
    route: '/roles/:id/hasEmployees',
    generate(id) {
      return generatePath(this.route, { id });
    },
  },
};
