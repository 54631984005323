import { styled } from '@hexa-ui/theme';

const StyledToastWrapper = styled('div', {
  position: 'fixed',
  width: '100%',
  zIndex: '4000',
  alignItems: 'flex-end',
  pointerEvents: 'auto !important',

  variants: {
    open: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
    position: {
      'bottom-left': {
        left: '100px',
        bottom: '30px',
        justifyContent: 'start',
      },
      'bottom-center': {
        bottom: '30px',
        justifyContent: 'center',
      },
      'bottom-right': {
        right: '30px',
        bottom: '30px',
        justifyContent: 'end',
      },
      'top-left': {
        top: '30px',
        left: '100px',
        alignItems: 'flex-start',
        justifyContent: 'start',
      },
      'top-center': {
        top: '30px',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      'top-right': {
        top: '30px',
        right: '30px',
        alignItems: 'flex-start',
        justifyContent: 'end',
      },
    },
  },
});

export { StyledToastWrapper };
