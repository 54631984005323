import { Alert as AlertComponent, Input, Paragraph } from '@hexa-ui/components-latest';
import { styled } from '@hexa-ui/theme';

import { TABLE_GRID_DEFAULT_STYLES, TRANSITION_COLOR } from './settings';

const Container = styled('form', {
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr auto', // search header wrapper footer
  flex: 1,
});

const AllocationsHeader = styled('div', {
  ...TABLE_GRID_DEFAULT_STYLES,
  padding: '$2 $6',
  borderTop: '1px solid $neutral30',
  backgroundColor: '$neutral20',
});

const HeaderCell = styled(Paragraph, {
  fontWeight: '$semibold !important',
  '&:not(.text)': {
    textAlign: 'right !important',
  },
});

const AllocationsWrapper = styled('div', {
  position: 'relative',
  display: 'grid',
  gridAutoRows: 'max-content',
  overflowY: 'auto',
});

const AllocationRow = styled('div', {
  ...TABLE_GRID_DEFAULT_STYLES,
  padding: '$4 $6',
  alignItems: 'center',
  borderBottom: '1px solid $neutral30',
  '& > div:not(.text)': {
    marginLeft: 'auto',
  },

  '.inTransition': {
    color: TRANSITION_COLOR,
  },
});

const AllocationInput = styled(Input, {
  textAlign: 'right',
  '-moz-appearance': 'textfield',
  '&::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const Alert = styled(AlertComponent, {
  display: 'flex',
  flex: 1,
  justifySelf: 'center',
  width: '96%',
  marginBlock: '16px',
});

export {
  Alert,
  AllocationInput,
  AllocationRow,
  AllocationsHeader,
  AllocationsWrapper,
  Container,
  HeaderCell,
};
