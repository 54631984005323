import { useLogService as logService } from 'admin-portal-shared-services';
import {
  CAPACITY_BFF_PATH,
  STRUCTURES_ROUTES,
  SUCCESS_CODE,
  VERTICALS_ROUTES,
} from 'constants/rest';
import qs from 'qs';
import Api from '../../Api/Api';
import {
  CreateStructureRequest,
  GetStructuresRequest,
  GetStructuresResponse,
  StructureHasValueStreamsResponse,
  StructurePaginationFilters,
  UpdateStructureRequest,
} from './StructureService.types';

const log = logService();

export const getStructuresByVertical = async <GetStructuresResponse>({
  verticalId,
  props = [],
}: GetStructuresRequest): Promise<GetStructuresResponse> => {
  const config = {
    params: {
      props,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  };

  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${VERTICALS_ROUTES.structuresByVertical.generate(verticalId)}`,
    config,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-structures-error', response);
      reject(response);
    }
  });
};

export const getStructures = async (): Promise<GetStructuresResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structures}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-structures-error', response);
      reject(response);
    }
  });
};

export const getStructuresWithPagination = async <StructurePaginationResponse>(
  pagination: Pagination,
  filters: StructurePaginationFilters = {}
): Promise<StructurePaginationResponse> => {
  const body = {
    filters,
    pagination,
  };

  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structuresPagination}`,
    body,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-structure-pagination-error', response);
      reject(response);
    }
  });
};

export const getStructureById = async <StructureView>(
  structureId: number
): Promise<StructureView> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structureById.generate(structureId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-structure-error', response);
      reject(response);
    }
  });
};

export const structuresExists = async (name: string): Promise<boolean> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structuresExists}?name=${encodeURIComponent(
      name
    )}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data.exists);
    } else {
      log.error('get-structures-exists-error', response);
      reject(response);
    }
  });
};

export const createStructure = async <StructureView>(
  newStructure: CreateStructureRequest
): Promise<StructureView> => {
  const response = await Api.post({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structures}`,
    body: newStructure,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('post-create-new-structure-error', response);
      reject(response);
    }
  });
};

export const updateStructure = async <StructureView>(
  updatedStructure: UpdateStructureRequest
): Promise<StructureView> => {
  const response = await Api.put({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structureById.generate(updatedStructure.id)}`,
    body: updatedStructure,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('update-structure-error', response);
      reject(response);
    }
  });
};

export const structureHasValueStreams = async (
  valueStreamId: number
): Promise<StructureHasValueStreamsResponse> => {
  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structureHasValueStreams.generate(
      valueStreamId
    )}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-structure-has-value-streams-error', response);
      reject(response);
    }
  });
};

export const getStructuresByVerticals = async (
  verticalIds: string[]
): Promise<GetStructuresResponse> => {
  const config = {
    params: { ...(verticalIds && { verticalIds }) },
  };

  const response = await Api.get({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structuresByVerticals}`,
    config,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response.data);
    } else {
      log.error('get-structures-by-verticals-error', response);
      reject(response);
    }
  });
};

export const deleteStructure = async (structureId: number): Promise<void> => {
  const response = await Api.delete({
    url: `${CAPACITY_BFF_PATH}${STRUCTURES_ROUTES.structureById.generate(structureId)}`,
  });

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(response.status)) {
      resolve(response);
    } else {
      log.error('delete-structure-error', response);
      reject(response);
    }
  });
};
