import { StructureView } from 'services/structure/StructureService.types';
import { TeamStatusString } from 'services/team/TeamService.types';
import { ValueStreamType } from 'services/valueStreamTypes/ValueStreamTypesService.types';
import { VerticalView } from 'services/vertical/VerticalService.types';

export enum ValueStreamStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type ValueStreamStatusString = keyof typeof ValueStreamStatus;

export type ValueStreamView = {
  id: number;
  name: string;
  links?: string[];
  situationStatus?: ValueStreamStatusString;
  vertical?: VerticalView;
  structure?: StructureView;
  guardians?: Guardian[];
  type?: Pick<ValueStreamType, 'id' | 'name'> | null;
};

export type ValueStreamPaginationView = {
  id: number;
  name: string;
  situationStatus: ValueStreamStatusString;
  vertical?: string;
  structure?: string;
  podAmount?: number;
  teamAmount?: number;
  peopleAmount: number;
  allocationAmount?: number;
};

export type GetValueStreamsRequest = {
  structureId: number;
  props?: string[];
};

export type GetValueStreamsResponse = {
  valueStreams: ValueStreamView[];
};

export type CreateValueStreamRequest = {
  name: string;
  verticalId: number;
  structureId: number;
  typeId?: number | null;
};

export type ValueStreamPaginationFilters = {
  names?: string[];
  verticals?: string[];
  structures?: string[];
  status?: ValueStreamStatus[];
  types?: string[];
};

export type GetValueStreamsByStructureRequest = {
  structureId: number;
  pagination: Pagination;
};

export type GetValueStreamsByStructureWithPaginationResponse = {
  pagination: Pagination;
  valueStreams: ValueStreamView[];
  totals?: {
    valueStream: number;
    team: number;
    people: number;
    allocation: number;
  };
};

export type ValueStreamPaginationResponse = {
  pagination: Pagination;
  filters?: ValueStreamPaginationFilters;
  valueStreams: ValueStreamPaginationView[];
};

export type UpdateValueStreamRequest = {
  id: number;
  name: string;
  verticalId: number;
  structureId: number;
  typeId: number | null;
  situationStatus: TeamStatusString;
  guardianIds?: number[];
};

export type ValueStreamHasTeamsResponse = {
  hasTeams: boolean;
};

export type GetValueStreamsByValueStreamTypeRequest = {
  valueStreamTypeId: number;
  pagination: Pagination;
};

export type GetValueStreamsByValueStreamTypeResponse = {
  pagination: Pagination;
  valueStreams: ValueStreamPaginationView[];
  totals?: {
    team: number;
    people: number;
    allocation: number;
    structure: number;
  };
};
