import { Paragraph } from '@hexa-ui/components-latest';
import { styled } from '@hexa-ui/theme';

const SearchBox = styled('div', {
  position: 'relative',
  display: 'grid',
  flex: 1,
  gridTemplateAreas: `
    'label label'
    'search button'
  `,
  gridTemplateColumns: '1fr auto',
  gap: '$4',
  padding: '0 $6 $6',
});

const Label = styled(Paragraph, {
  gridArea: 'label',
});

export { Label, SearchBox };
