import { generatePath } from 'react-router-dom';

export const BULK_WORKDAY_ROUTES = {
  workdayInfo: '/bulk/employees/workdayInfo',
  workdayStatus: '/bulk/employees/workdayInfo/status',
  workdayLogs: '/bulk/employees/workdayInfo/logs',
  workdayDownload: {
    route: '/bulk/employees/workdayInfo/:bulkWorkdayId/download',
    generate(bulkWorkdayId) {
      return generatePath(this.route, { bulkWorkdayId });
    },
  },
};
