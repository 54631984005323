import { CompanyFilters } from 'services/company/CompanyService.types';
import { CapacityPaginationFilters } from '../services/capacity/CapacityService.types';
import { EmployeePaginationFilters } from '../services/employee/EmployeeService.types';
import { StructurePaginationFilters } from '../services/structure/StructureService.types';
import { TeamPaginationFilters } from '../services/team/TeamService.types';
import { ValueStreamPaginationFilters } from '../services/valueStream/ValueStreamService.types';

export const allKeysOfTeamPaginationFilters: Array<keyof TeamPaginationFilters> = [
  'names',
  'verticals',
  'structures',
  'valueStreams',
  'status',
];

export const allKeysOfEmployeePaginationFilters: Array<keyof EmployeePaginationFilters> = [
  'names',
  'verticals',
  'structures',
  'valueStreams',
  'teams',
  'status',
  'companies',
  'roles',
  'lineManagers',
];

export const allKeysOfValueStreamPaginationFilters: Array<keyof ValueStreamPaginationFilters> = [
  'names',
  'verticals',
  'structures',
  'status',
];

export const allKeysOfStructurePaginationFilters: Array<keyof StructurePaginationFilters> = [
  'names',
  'verticals',
  'status',
];

export const allKeysOfCapacityPaginationFilters: Array<keyof CapacityPaginationFilters> = [
  'names',
  'verticals',
];

export const allKeysOfCompanyPaginationFilters: Array<keyof CompanyFilters> = ['names'];
