import { belongsTo, hasMany, Model } from 'miragejs';
const models = {
  vertical: Model.extend({
    structures: hasMany(),
  }),
  structure: Model.extend({
    vertical: belongsTo(),
    valueStreams: hasMany(),
    director: belongsTo(),
  }),
  valueStream: Model.extend({
    structure: belongsTo(),
    teams: hasMany(),
  }),
  team: Model.extend({
    valueStream: belongsTo(),
    stack: belongsTo(),
    director: belongsTo(),
    employees: hasMany(),
    allocations: hasMany(),
    allocationPlannings: hasMany(),
  }),
  director: Model.extend({
    teams: hasMany(),
    structures: hasMany(),
  }),
  stack: Model.extend({
    teams: hasMany(),
  }),
  employee: Model.extend({
    teams: hasMany(),
    role: belongsTo(),
    company: belongsTo(),
    allocations: hasMany(),
    lineManager: belongsTo('employee'),
    logs: hasMany(),
  }),
  allocation: Model.extend({
    team: belongsTo(),
    employee: belongsTo(),
  }),
  company: Model.extend({
    employees: hasMany(),
  }),
  role: Model.extend({
    employees: hasMany(),
    group: belongsTo('roleGroup'),
  }),
  roleGroup: Model.extend({
    roles: hasMany(),
  }),
  allocationPlanning: Model.extend({
    team: belongsTo(),
    planningDate: belongsTo('date'),
  }),
  date: Model.extend({
    allocationPlannings: hasMany(),
  }),
  log: Model.extend({
    employee: belongsTo(),
  }),
  meeting: Model.extend(),
};
export default models;
