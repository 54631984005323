import { usePermissionService } from 'admin-portal-shared-services';
import Loading from 'components/Loading';
import { BASE_NAME } from 'constants/appRoutes';
import { useAppHeader } from 'providers/AppHeaderProvider';
import { useAuth } from 'providers/AuthProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes';

interface PrivateRouteProps {
  route: JSX.Element;
}
const PrivateRoute = ({ route }: PrivateRouteProps) => {
  let location = useLocation();
  const navigate = useNavigate();
  const { isLoading, userHasDevParametersPermission } = useAuth();
  const { setIsVisible } = useAppHeader();
  const [accessAllowed, setAccessAllowed] = useState<boolean>(true);
  const permissionService = usePermissionService();

  const routeItem = useMemo(
    () => routes.find((item) => matchPath(item.path, location.pathname)),
    [routes, location.pathname]
  );

  const checkUserPermissions = useCallback(() => {
    let canAccess = false;

    const isDevRoute = routeItem?.path.includes('/devs');

    if (!userHasDevParametersPermission && isDevRoute) {
      canAccess = userHasDevParametersPermission;
    } else if (routeItem?.scopes) {
      canAccess = permissionService.hasOneOfScopes(routeItem.scopes);
    }

    setAccessAllowed(canAccess);
    setIsVisible(canAccess);
  }, [userHasDevParametersPermission, permissionService, routeItem]);

  useEffect(() => {
    if (!isLoading) {
      checkUserPermissions();
    }
  }, [isLoading, checkUserPermissions]);

  useEffect(() => {
    if (!accessAllowed) {
      navigate(`${BASE_NAME}/403`, { state: { from: location } });
    }
  }, [accessAllowed]);

  return isLoading ? <Loading /> : route;
};

export default PrivateRoute;
