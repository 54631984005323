import { useDisclosure } from 'hooks/useDisclosure';
import { createContext, useContext, useState } from 'react';
import Dialog, { DialogProps } from './Dialog';

type DialogContext = {
  props: DialogProps;
  isOpen: boolean;
  showDialog: (props?: DialogProps) => void;
  hideDialog: () => void;
  updateProps: (props?: DialogProps) => void;
};

/* istanbul ignore next */
const initalState: DialogContext = {
  props: {},
  isOpen: false,
  showDialog: () => {},
  hideDialog: () => {},
  updateProps: () => {},
};

const DialogContext = createContext(initalState);

export const DialogProvider = ({ children }) => {
  const [props, setProps] = useState({});
  const { isOpen, openDialog, closeDialog } = useDisclosure();

  const showDialog = (props: DialogProps = {}) => {
    setProps(props);
    openDialog();
  };

  const hideDialog = () => {
    setProps({});
    closeDialog();
  };

  const updateProps = (props: DialogProps = {}) => {
    setProps((prevState) => ({ ...prevState, ...props }));
  };

  return (
    <DialogContext.Provider value={{ props, isOpen, showDialog, hideDialog, updateProps }}>
      <Dialog />
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext(DialogContext);
