import { Card, Paragraph } from '@hexa-ui/components-latest';
import { styled } from '@hexa-ui/theme';
import { visibilityConfig } from 'styles/globalStyles';
import { TABLE_GRID_DEFAULT_STYLES } from '../../settings';

const Container = styled('div', {
  ...TABLE_GRID_DEFAULT_STYLES,
  borderTop: '1px solid $neutral30',
  padding: '$6 $6 0',
  alignSelf: 'flex-end',
  marginTop: 'auto',
});

const RecalculateContainer = styled('div', {
  position: 'relative',
  width: 'fit-content',
});

const BoxItems = styled(Card, {
  ...visibilityConfig,
  width: '340px',
  padding: 0,
  zIndex: '$1',
  listStyle: 'none',
  position: 'absolute',
  bottom: '32px',
  left: '80%',

  li: {
    listStyleType: 'none',

    '& > div': {
      padding: '$2 $4',
      margin: 0,
      width: '100%',
      borderRadius: '$4',
    },
  },
});

const recalculateButtonStyles = {
  gap: '8px',
  fontWeight: 'bold',
  '& > svg, & > svg:active ': {
    fill: '#000 !important',
  },
  '&:disabled > svg': {
    opacity: 0.7,
  },
};

const Total = styled(Paragraph, {
  fontWeight: '$medium !important',
  textAlign: 'right !important',
  '.has-error': {
    color: '#E4423F',
  },
});

export { BoxItems, Container, recalculateButtonStyles, RecalculateContainer, Total };
