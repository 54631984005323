import { Button } from '@hexa-ui/components';
import { useDialogContext } from 'providers';
import { useNavigate } from 'react-router-dom';
import { useEmployeeFormStore } from 'stores';

interface dialogProps {
  isAllocation?: boolean;
}

const useCancelDialog = () => {
  const { showDialog, hideDialog } = useDialogContext();
  const navigate = useNavigate();

  const { isEditForm } = useEmployeeFormStore();

  const showCancelDialog = ({ isAllocation }: dialogProps) => {
    return showDialog({
      title: 'Warning',
      description:
        isEditForm || isAllocation
          ? 'Are you sure you want to cancel editing this information? All unsaved changes will be lost.'
          : 'Are you sure you want to cancel adding this person? All unsaved changes will be lost.',
      spacedActions: true,
      actions: (
        <>
          <Button
            variant="secondary"
            data-testid="continue-operation-button"
            onClick={() => {
              hideDialog();
            }}
          >
            Continue {isEditForm || isAllocation ? 'editing' : 'adding'}
          </Button>

          <Button
            data-testid="confirm-cancel-button"
            onClick={() => {
              hideDialog();
              navigate(-1);
            }}
          >
            Exit without saving
          </Button>
        </>
      ),
    });
  };

  return { showCancelDialog };
};

export default useCancelDialog;
