import { styled } from '@hexa-ui/theme';

const SearchAutoCompleteContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '18vw',
  minWidth: 270,
  '& p': { fontSize: '$2' },

  variants: {
    size: {
      full: {
        width: '100%',
        maxWidth: 'inherit',
      },

      medium: {
        width: '18vw',
        '& p': { fontSize: '$2' },
      },

      large: {
        width: '22vw',
        '& p': { fontSize: '$4' },
      },

      extraLarge: {
        width: '32vw',
        minWidth: '600px',
        '& p': { fontSize: '$4' },
      },
    },
  },
});

const SearchFieldContainer = styled('div', {});

const ComboBoxContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '56px',
  zIndex: '$2',
  boxShadow: '$4',
  borderRadius: '$4',

  variants: {
    isOpen: {
      false: {
        height: 0,
        visibility: 'hidden',
      },
    },
    full: {
      true: {
        marginTop: '16px',
      },
    },
    hasError: {
      true: {
        marginTop: '38px',
      },
    },
  },
});

const SearchAutoCompleteListBoxHeader = styled('div', {
  width: '100%',
  height: 10,
  backgroundColor: '$neutral0',
  borderRadius: '$full $full 0 0',
});

const SearchAutoCompleteListbox = styled('ul', {
  width: '100%',
  listStyle: 'none',
  overflow: 'auto',
  cursor: 'pointer',
  margin: 0,
  paddingLeft: 0,
  boxSizing: 'border-box',
  backgroundColor: '$neutral0',
  maxHeight: '40vh',

  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '$4',
    backgroundColor: '$interfaceSurfaceSecondary',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '$interfaceLabelPrimary',
  },

  '& :active': {
    '& p': { fontWeight: 'bold' },
  },
});

const SearchAutoCompleteListItem = styled('li', {
  width: '100%',
  overflow: 'hidden',
  padding: '$1 $10 $1 $10',

  variants: {
    selected: {
      true: {
        backgroundColor: '$neutral20',
      },
    },
  },
});

const SearchAutoCompleteListBoxFooter = styled('div', {
  width: '100%',
  height: 10,
  backgroundColor: '$neutral0',
  borderRadius: '0 0 $full $full',
});

export {
  ComboBoxContainer,
  SearchAutoCompleteContainer,
  SearchAutoCompleteListBoxFooter,
  SearchAutoCompleteListBoxHeader,
  SearchAutoCompleteListItem,
  SearchAutoCompleteListbox,
  SearchFieldContainer,
};
