import { UserUpdatedInformation } from 'services/capacity/CapacityService.types';

export enum EmployeeStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  InTransition = 'In Transition',
}

export type EmployeeStatusString = keyof typeof EmployeeStatus;

export type CreateEmployeeRequest = {
  id?: number;
  name: string;
  email: string;
  employeeNumber?: number;
  vendorId?: string;
  roleId: number;
  lineManagerId: number;
  isLineManager: boolean;
  companyId: number;
  allocations?: EmployeeAllocation[];
  alternativeEmails: string[];
  fundedBy?: number;
};

export type EmployeeView = {
  id: number;
  name: string;
  email?: string;
  employeeNumber?: number;
  vendorId?: number;
  roleId?: string;
  role?: { id: number; name: string } | string;
  lineManagerId?: number;
  isLineManager?: boolean;
  companyId?: string;
  situationStatus: EmployeeStatusString;
  inactivatedBy?: string;
  inactivatedOn?: string;
  company?: { id: number; name: string };
  fundedBy?: {
    id: number;
    name: string;
  };
};

export type EmployeeProps = {
  id: number;
  name: string;
  email: string;
  employeeNumber?: number;
  vendorId?: string;
  companyId?: number;
  roleId?: number;
  fundedBy?: {
    id: number;
    name: string;
  };
  company: {
    id: number;
    name: string;
    external: boolean;
  };
  role: {
    id: number;
    name: string;
  };
  lineManager: { name: string; id: number } | string;
  situationStatus: EmployeeStatusString;
  alternativeEmails: string[];
  isLineManager: boolean;
  band?: string;
};

export interface GetEmployeeByIdResponse extends EmployeeProps {
  jobFamily: string;
  career: string;
  positionTitle: string;
  inactivatedBy: string;
  inactivatedOn: string;
}

export type verifyEmployeeExistsBy = 'email' | 'employeeNumber';

export type GetEmployeesByTeamRequest = {
  teamId: number;
  pagination: Pagination;
};

export type EmployeeResponse = {
  name: string;
  email: string;
  employeeNumber?: string;
  vendorId?: number;
};

export type EmployeePaginationFilters = {
  names?: string[];
  searchEmployees?: string[] | EmployeeResponse[];
  verticals?: string[];
  structures?: string[];
  valueStreams?: string[];
  teams?: string[];
  status?: EmployeeStatusString[];
  companies?: string[];
  roles?: string[];
  lineManagers?: string[];
};

export type EmployeePaginationFiltersV2 = {
  search?: string;
  verticals?: (string | number)[];
  structures?: (string | number)[];
  valueStreams?: (string | number)[];
  teams?: (string | number)[];
  status?: EmployeeStatusString[];
  companies?: (string | number)[];
  roles?: (string | number)[];
  lineManagers?: (string | number)[];
};

export type EmployeePaginationFiltersSearchResponse = {
  searchEmployees?: string[] | EmployeeResponse[];
};

export type EmployeePaginationFiltersResponse = {
  filters?: EmployeePaginationFilters;
};

export type EmployeeTotalization = {
  name: number;
  role: number;
  company: number;
  allocation: number;
};

export type EmployeePaginationResponse = {
  totals: EmployeeTotalization;
  pagination: Pagination;
  filters?: EmployeePaginationFilters;
  employees: EmployeePaginationView[];
  onlyWithoutTeam: boolean;
};

export type EmployeePaginationView = {
  id: number;
  name: string;
  role: string;
  company: string;
  allocation: number;
  teams?: string[];
  lineManager?: string | { name: string; id: number };
  verticals?: string[];
  structures?: string[];
  valueStreams?: string[];
  situationStatus?: EmployeeStatusString;
  isLineManager?: boolean;
};

export type EmployeeModalView = {
  id: number;
  name: string;
  roleId?: number;
};

export type AllocationHierarchyInfo = {
  vertical: string;
  structure: string;
  valueStream: string;
};

export type EmployeeAllocation = {
  id: number;
  teamName?: string;
  teamId?: number;
  percentage: number;
  lastMonthPercentage?: number | null;
  hierarchyInfo?: AllocationHierarchyInfo;
};

export type EmployeeAllocationTotals = {
  percentage: number;
  lastMonthPercentage: number;
};

export type GetAllocationsByEmployeeResponse = {
  allocations: EmployeeAllocation[];
  totals: EmployeeAllocationTotals;
};

export type GetEmployeesResponse = {
  employees: EmployeeView[];
};

export type UpdateAllocationsRequest = {
  employeeId: number;
  allocations: EmployeeAllocation[];
};

export type GetDirectorsResponse = {
  directors: EmployeeView[];
};

export type GetDirectorsRequest = {
  props?: string[];
};

export type EmployeesHistoryTeams = {
  id: number;
  name: string;
};

export type GetEmployeesHistoryTeamsResponse = {
  teams: EmployeesHistoryTeams[];
};

export type EmployeesHistoryRangeDates = {
  value: string;
};

export type GetEmployeesHistoryRangeDatesResponse = {
  dates: EmployeesHistoryRangeDates[];
};

export type GetEmployeeHistoryRequest = {
  employeeId: number;
  startMonth: string;
  endMonth: string;
};

export type HistoryValues = {
  date: string;
  value: number;
};

export type EmployeeHistory = {
  id: number;
  historyValues: HistoryValues[];
};

export type GetEmployeeHistoryResponse = {
  teams: EmployeeHistory[];
};

export type EventHistoryLog = {
  type: string;
  title?: string;
  field?: string;
  from?: string;
  to?: string;
  description?: string;
  details?: {
    email: string;
    name: string;
    lineManager: string;
    isLineManager: string;
    employeeNumber: number;
    vendorId: string;
    role: string;
    company: string;
    fundedBy?: string;
    band?: string;
    positionTitle?: string;
  };
};

export type EmployeeHistoryLogs = {
  dateTime: string;
  authorId: string;
  authorName: string;
  events: EventHistoryLog[];
};

export type GetEmployeeHistoryLogsResponse = {
  logs: EmployeeHistoryLogs[];
};

export type GetEmployeeRoleAndNameResponse = {
  name: string;
  role: string;
};

export type EmployeeLastMonthAvailableResponse = {
  lastMonthAvailable: boolean;
};

export type EmployeeAllocationLimitDatesResponse = {
  maximum: string;
};

export type EmployeesRolesResponse = {
  employees: EmployeeModalView[];
};

export type EmployeeRoleChange = {
  roleId: number;
  employeeIds: number[];
};

export type EmployeesRolesUpdateRequest = {
  employeesToChange: EmployeeRoleChange[];
};

export type EmployeeUpdateInformationResponse = {
  date?: string;
  user?: UserUpdatedInformation;
};

export type EmployeeTransitionRequest = {
  employeeId: number;
  structureId?: number;
};

export type DownloadEmployeeAllocationHistoryRequest = {
  filters?: EmployeePaginationFilters;
  onlyWithoutTeam?: boolean;
  startMonth: string;
  endMonth: string;
};

export type EmployeeTeamGuardian = {
  id: number;
  name: string;
};

export type GetEmployeeGuardianInfoResponse = {
  teams: EmployeeTeamGuardian[];
};
