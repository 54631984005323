import { Card } from '@hexa-ui/components-latest';
import { styled } from '@hexa-ui/theme';

const Container = styled(Card, {
  display: 'flex',
  flex: 1,
  padding: '$6 0',
  height: '78vh',
});

const BoxButtons = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$2',
  padding: '$6 $6 0',
  borderTop: '1px solid $neutral30',
  marginTop: '$6',
});

export { BoxButtons, Container };
