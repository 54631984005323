import { EmployeePaginationFilters } from 'services/employee/EmployeeService.types';
import { create } from 'zustand';

interface EmployeeManagementState {
  filters: EmployeePaginationFilters;
  isOnlyWithoutTeam: boolean;

  setFilters: (filters: EmployeePaginationFilters) => void;
  setIsOnlyWithoutTeam: (onlyWithoutTeam: boolean) => void;
}

export const useEmployeeManagementStore = create<EmployeeManagementState>((set) => ({
  filters: {},
  isOnlyWithoutTeam: false,

  setFilters: (filters) => set({ filters }),
  setIsOnlyWithoutTeam: (isOnlyWithoutTeam) => set({ isOnlyWithoutTeam }),
}));
