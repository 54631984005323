import { LoadingBuzz } from '@hexa-ui/components';
import { LoadingWrapper } from './styles';

const LoadingPage = () => {
  return (
    <LoadingWrapper>
      <LoadingBuzz dataTestId="transition-spinner" />
    </LoadingWrapper>
  );
};

export default LoadingPage;
