import { EmployeeAllocation } from 'services/employee/EmployeeService.types';

export const validateSumDiffGreaterThanNewPercentage = (sumDiff: number, newPercentage: number) =>
  sumDiff < 0 && Math.abs(sumDiff) > newPercentage;

export const normalizeFloatValue = (value: number) => Number(value.toFixed(2));

export const insertTeamAndCalculateNewPercentage = (
  employeeAllocations: EmployeeAllocation[],
  newTeam: { teamId: number; teamName: string }
): EmployeeAllocation[] => {
  const alreadyAdded = employeeAllocations.find((team) => team.teamName === newTeam.teamName);

  if (alreadyAdded) {
    throw new Error('This POD has already been added for this user');
  }

  const allocationCurrentValue = normalizeFloatValue(
    employeeAllocations.reduce((accumulator, object) => accumulator + Number(object.percentage), 0)
  );

  if (allocationCurrentValue >= 1) {
    const newPercentage = normalizeFloatValue(1 / (employeeAllocations.length + 1));

    const newSum = newPercentage * (employeeAllocations.length + 1);
    const sumDiff = 1 - newSum;

    let sumDiffTemp = Math.abs(sumDiff);
    employeeAllocations = employeeAllocations.map((item) => {
      if (validateSumDiffGreaterThanNewPercentage(sumDiff, newPercentage) && sumDiffTemp > 0.02) {
        sumDiffTemp = sumDiffTemp - 0.01;

        return {
          ...item,
          percentage: normalizeFloatValue(newPercentage - 0.01),
        };
      }

      return {
        ...item,
        percentage: normalizeFloatValue(newPercentage),
      };
    });

    employeeAllocations.push({
      ...newTeam,
      id: null,
      percentage: normalizeFloatValue(
        validateSumDiffGreaterThanNewPercentage(sumDiff, newPercentage)
          ? newPercentage - 0.01
          : newPercentage + sumDiff
      ),
    });
  } else {
    employeeAllocations.push({
      ...newTeam,
      id: null,
      percentage: normalizeFloatValue(((1.0 - allocationCurrentValue) / 1000) * 1000),
    });
  }

  return employeeAllocations;
};

export const calculateTotalEmployeeAllocation = (
  employeeAllocations: EmployeeAllocation[],
  accessor: string
) => {
  const totalPercentageValue = employeeAllocations.reduce(
    (accumulator, object) =>
      Number(accumulator.toFixed(2)) + (object[accessor] ? Number(object[accessor].toFixed(2)) : 0),
    0
  );
  return totalPercentageValue;
};

export const changeToIntegerOrDecimal = (numberAllocation: number) => {
  return numberAllocation === 1 || numberAllocation === 0
    ? numberAllocation.toFixed()
    : numberAllocation?.toFixed(2);
};
