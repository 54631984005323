export const formatToLowerCasedAndIgnoreAccents = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim();
};

export const verifyIfOptionLabelIncludesValue = (optionLabel: string, inputValue: string) => {
  const formattedInputValue = formatToLowerCasedAndIgnoreAccents(inputValue);
  return formatToLowerCasedAndIgnoreAccents(optionLabel).includes(formattedInputValue);
};
